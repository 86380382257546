import { ACCESS_GLOBAL } from 'variables';

const NafathRoutes = [
  {
    path: `nafath`,
    componentPath: 'nafath',
    template: 'NafathLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `nafath/success`,
    componentPath: 'nafath/success',
    template: 'NafathLayout',
    accessType: ACCESS_GLOBAL,
  },
];
export default NafathRoutes;
