import i18next from 'i18next';

const Tracking = {};

Tracking.init = function () {
  if (window.dataLayer) {
    return;
  }
  window.dataLayer = [
    {
      language: i18next.language,
    },
  ];

  // Google Tag Manager
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l !== 'dataLayer' ? `&l=${l}` : '';
    j.async = true;
    j.src = `https://www.googletagmanager.com/gtm.js?id=${i}${dl}`;
    j.onload = () => {};
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', process.env.REACT_APP_GOOGLE_TAG_MANAGER_APP_ID);
  // End Google Tag Manager
};

Tracking.eCommerce_event = (customEventName, eCommerceObject) => {
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer = window.dataLayer || [];
  const obj = {
    event: 'eventTracker',
    custom_event_name: customEventName,
    ...eCommerceObject,
  };
  window.dataLayer?.push(obj);
};

Tracking.custom_event = (customEventName, extraObj) => {
  const obj = {
    event: 'eventTracker',
    custom_event_name: customEventName,
    page_url: window.location.href,
    ...extraObj,
  };
  window.dataLayer?.push(obj);
};

Tracking.page_view_event = function (obj) {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(obj);
};

export default Tracking;
