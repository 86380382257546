import keycode from 'keycode';
import { GET_URL_PATH_FIRST_PART, GET_URL_PATH_LAST_PART } from './variables';
// import { setStorageElement } from './services/storage.service';

export const getParams = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};

export const getDirection = (lang) => {
  if (lang === 'en') {
    return 'LTR';
  }
  return 'RTL';
};

export const getDefaultLanguage = () => process.env.REACT_APP_DEFAULT_LANGUAGE;

export const getFullPathWithoutLang = (path = window.location.pathname) => path.substr(4);

export const handleErrorCode = (error) => {
  let errorCode = error;
  if (error && error.data) {
    errorCode = error.data.code;
  }
  return errorCode;
};

export const handleError = (error, type) => {
  let errorMsg = '';
  if (error && error.data) {
    const data = error.data;
    if (type === 'desc') {
      if (data.source === 'semati') {
        errorMsg = `semati_error_desc_${data.code}`;
      } else {
        errorMsg = data.code?.toString();
      }
    } else if (type === 'title') {
      if (data.source === 'semati') {
        errorMsg = `semati_error_title_${data.code}`;
      } else {
        errorMsg = data.error;
      }
    } else {
      errorMsg = data.error;
    }
  }
  return errorMsg;
};

export const handleErrorDetails = (error) => {
  let errorDetails = error;
  if (error && error.data) {
    errorDetails = error.data.details;
  }
  return errorDetails;
};

export const promiseHandler = async (promise) => {
  try {
    const response = await promise();
    // const data = response.data;
    // Check all attributes for null and store the names of attributes with null values
    // const nullAttributes = Object.keys(data).filter((key) => data[key] === null);
    // console.log('nullAttributes', nullAttributes);
    // if (data === null || nullAttributes.length > 0) {
    //   console.log('errorObj/promiseHandler', data, nullAttributes);
    //   setStorageElement(
    //     'errorMsg',
    //     `API response contains null attributes: ${nullAttributes.join(', ')}`
    //   );
    // }
    return [response.data, null];
  } catch (error) {
    const response = error.response;
    const errorMsg = handleError(response, 'title');
    const errorCode = handleErrorCode(response);
    const details = handleErrorDetails(response);
    const errorDesc = handleError(response, 'desc');
    const erroObj = response?.data || response;
    return [null, errorMsg, errorCode, details, erroObj, errorDesc];
  }
};

export const formatPhoneNumber = (msisdn) => {
  let value = msisdn;
  if (value) {
    if (value.startsWith(0)) {
      value = value.substring(1);
    }
    if (!value.startsWith(9)) {
      value = `966${value}`;
    }
    return `${value.substr(0, 3)} ${value.substr(3)}`;
  }
};

// datepart: 'y', 'm', 'w', 'd', 'h', 'n', 's'
export const dateDiff = (datepart, fromdate, todate) => {
  const diff = todate - fromdate;
  const divideBy = {
    w: 604800000,
    d: 86400000,
    h: 3600000,
    n: 60000,
    s: 1000,
  };

  return Math.floor(diff / divideBy[datepart.toLowerCase()]);
};
const convertTime = (backEndDate) => {
  const timeString = backEndDate.split('T')[1];
  const timeString12hr = new Date(`1970-01-01T${timeString}`).toLocaleTimeString(
    {},
    { timeZone: 'Asia/Riyadh', hour12: true, hour: 'numeric', minute: 'numeric' }
  );
  return `${timeString12hr}`;
};

export const getDate = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1; // months from 1-12
  const year = dateObj.getUTCFullYear();
  return `${convertTime(date)} ${day}/${month}/${year}`;
};

export const getDateWithoutTime = (date) => {
  const dateObj = new Date(date);
  const day = dateObj.getUTCDate();
  const month = dateObj.getUTCMonth() + 1; // months from 1-12
  const year = dateObj.getUTCFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDueDateWithMonthAsText = (date, i18n) => {
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  const dateObj = new Date(date).toLocaleString(
    `${i18n.language}-${i18n.language === 'en' ? 'GB' : 'EG'}`,
    options
  );
  return `${dateObj}`;
};

export const getTimeStamp = () => {
  const currentDate = new Date();
  const timestamp = currentDate.getTime();
  return timestamp;
};

export const getPath = (path = window.location.pathname, part = GET_URL_PATH_LAST_PART) => {
  const newPath = path.substr(4);
  if (part === GET_URL_PATH_FIRST_PART) {
    const indexOfBackSlash = newPath.indexOf('/');
    if (indexOfBackSlash !== -1) {
      return newPath.substring(0, indexOfBackSlash);
    }
    return newPath;
  }
  //  part = GET_URL_PATH_LAST_PART
  if (newPath.lastIndexOf('/') === newPath.length - 1) {
    return newPath.substr(newPath.lastIndexOf('/') + 1, newPath.length - 1);
  }
  return newPath.substr(newPath.lastIndexOf('/') + 1);
};

export const getPathLastPart = (path) => {
  if (path.lastIndexOf('/') === path.length - 1) {
    return path.substr(path.lastIndexOf('/') + 1, path.length - 1);
  }
  return path.substr(path.lastIndexOf('/') + 1);
};

export const isAllowedKey = (e) =>
  (e.metaKey && e.code === 'KeyA') ||
  (e.metaKey && e.code === 'KeyV') ||
  (e.metaKey && e.code === 'KeyC') ||
  keycode(e) === 'delete' ||
  keycode(e) === 'backspace' ||
  keycode(e) === 'enter' ||
  keycode(e) === 'tab' ||
  keycode(e) === 'left command' ||
  keycode(e) === 'right command' ||
  keycode(e) === 'left' ||
  keycode(e) === 'right' ||
  ((keycode(e) === '0' ||
    keycode(e) === '1' ||
    keycode(e) === '2' ||
    keycode(e) === '3' ||
    keycode(e) === '4' ||
    keycode(e) === '5' ||
    keycode(e) === '6' ||
    keycode(e) === '7' ||
    keycode(e) === '8' ||
    keycode(e) === '9' ||
    keycode(e) === 'numpad 0' ||
    keycode(e) === 'numpad 1' ||
    keycode(e) === 'numpad 2' ||
    keycode(e) === 'numpad 3' ||
    keycode(e) === 'numpad 4' ||
    keycode(e) === 'numpad 5' ||
    keycode(e) === 'numpad 6' ||
    keycode(e) === 'numpad 7' ||
    keycode(e) === 'numpad 8' ||
    keycode(e) === 'numpad 9') &&
    !/\D/.test(+e.key));

export const isDigit = (e) =>
  keycode(e) === '0' ||
  keycode(e) === '1' ||
  keycode(e) === '2' ||
  keycode(e) === '3' ||
  keycode(e) === '4' ||
  keycode(e) === '5' ||
  keycode(e) === '6' ||
  keycode(e) === '7' ||
  keycode(e) === '8' ||
  keycode(e) === '9' ||
  keycode(e) === 'numpad 0' ||
  keycode(e) === 'numpad 1' ||
  keycode(e) === 'numpad 2' ||
  keycode(e) === 'numpad 3' ||
  keycode(e) === 'numpad 4' ||
  keycode(e) === 'numpad 5' ||
  keycode(e) === 'numpad 6' ||
  keycode(e) === 'numpad 7' ||
  keycode(e) === 'numpad 8' ||
  keycode(e) === 'numpad 9';

export const onlyDigits = (event) => {
  if (!isAllowedKey(event)) {
    event.preventDefault();
  }
};

export const downloadFile = (src, fileName) => {
  const link = document.createElement('a');
  link.href = src;
  link.setAttribute('download', `${fileName}.png`); // or any other extension
  document.body.appendChild(link);
  link.click();
};

export const isAppleSafari = () => {
  const userAgentStr = navigator.userAgent.toLowerCase();
  if (
    userAgentStr.indexOf('mac') !== -1 &&
    userAgentStr.indexOf('safari/') !== -1 &&
    userAgentStr.indexOf('chrome/') === -1
  ) {
    return true;
  }
  return false;
};

export const isMacOperatingSystem = () => {
  if (window.navigator.appVersion.indexOf('Mac') !== -1) {
    return true;
  }
  return false;
};

export const isBrowsingFromMobile = () => window.navigator.userAgent.toLowerCase().includes('mobi');
export const isComingFromInformativeWebsite = () =>
  document.referrer.includes('mobile.salammobile.sa/');
