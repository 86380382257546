import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import pages from 'routes/pages';

const generateRoutes = (pages) =>
  pages.map((routeElem) => {
    const C = lazy(() =>
      import(/* webpackChunkName: "[request]" */ `../pages/${routeElem.componentPath}`)
    );
    return (
      <Route
        key={routeElem.path}
        path={`/:lang/${routeElem.path}`}
        exact
        render={(props) => (
          <>
            <C {...props} />
          </>
        )}
      />
    );
  });

const Routes = () => {
  const routeElements = generateRoutes(pages);
  return (
    <Suspense fallback={<div style={{ minHeight: '100vh' }} />}>
      <Switch>{routeElements}</Switch>
    </Suspense>
  );
};
export default Routes;
