import React from 'react';
import PropTypes from 'prop-types';
import ActionSheet from 'components/shared/action-sheet';
import IconClose from 'components/icons/close';
import blackCloseIcon from 'assets/img/blackCloseIcon.svg';
import Button from '../button';
import classes from './style.module.scss';

const Modal = ({
  desc,
  title,
  height,
  cancel,
  close,
  imgSrc,
  subTitle,
  children,
  iconClass,
  confirmation,
  subTitleStyle,
  titleExtraStyle,
  width = '100%',
  splitWith = '\n',
  cancelButtonTitle,
  cancelButtonAction,
  specialModalButton,
  specialClassInner,
  specialClassWrapper,
  specialClassContent,
  hideCloseIcon = false,
  specialBackgroundColor,
  confirmationButtonTitle,
  specialActionSheetClassWrapper,
}) => (
  // TODO: fix onClick={cancel || close} confusion
  <>
    <div className={`${classes.modal} ${specialClassWrapper}`}>
      <div
        className={`${classes.modalWrapper} ${specialClassInner}`}
        style={{ backgroundColor: specialBackgroundColor, height, width }}
      >
        <div>
          {!hideCloseIcon && (
            <div
              className={
                specialBackgroundColor
                  ? `${classes.modalClose} closeIcon`
                  : `${classes.modalClose} ${classes.modalCloseWithoutBackground} closeIcon`
              }
              role="button"
              aria-hidden="true"
              onClick={cancel || close}
            >
              {specialBackgroundColor ? (
                <IconClose className={classes.icon} />
              ) : (
                <img src={blackCloseIcon} alt={blackCloseIcon} />
              )}
            </div>
          )}
          <div className={`${classes.modalContent} ${specialClassContent}`}>
            {children || (
              <>
                {imgSrc && <img src={imgSrc} alt={imgSrc} className={iconClass} />}
                {title && <div className={`${classes.title} ${titleExtraStyle}`}>{title}</div>}
                {subTitle &&
                  subTitle
                    .split(splitWith)
                    .map((item) => (
                      <div className={`${classes.subTitle} ${subTitleStyle}`}>{item}</div>
                    ))}
                {desc?.split('\n').map((item) => (
                  <div className={`${classes.desc}`}>{item}</div>
                ))}
                <div className={`${classes.modalButtons} ${specialModalButton}`}>
                  {confirmationButtonTitle && (
                    <Button
                      type="button"
                      theme="primary"
                      onClick={confirmation}
                      label={confirmationButtonTitle}
                      className={classes.confirmButton}
                    />
                  )}
                  {cancelButtonTitle && (
                    <Button
                      type="button"
                      theme="secondary"
                      onClick={cancelButtonAction || cancel || close}
                      label={cancelButtonTitle}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
    <div className={`${classes.actionSheet} ${specialActionSheetClassWrapper}`}>
      <ActionSheet
        width={560}
        close={cancel || close}
        textAlignment="center"
        backgroundColor={specialBackgroundColor}
      >
        <div>
          <div
            className={`${classes.modalClose} closeIcon`}
            role="button"
            aria-hidden="true"
            onClick={cancel || close}
          >
            <IconClose className={classes.icon} />
          </div>
          <div className={classes.modalContent}>
            {children || (
              <>
                <img src={imgSrc} alt={imgSrc} />
                <div className={`${classes.title} ${titleExtraStyle}`}>{title}</div>
                <div className={classes.subTitle}>{subTitle}</div>
                <div className={classes.desc}>{desc}</div>
                <div className={classes.modalButtons}>
                  {confirmationButtonTitle && (
                    <Button
                      type="button"
                      theme="primary"
                      onClick={confirmation}
                      label={confirmationButtonTitle}
                      className={classes.confirmButton}
                    />
                  )}
                  {cancelButtonTitle && (
                    <Button
                      type="button"
                      theme="secondary"
                      onClick={cancel}
                      label={cancelButtonTitle}
                    />
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </ActionSheet>
    </div>
  </>
);
Modal.propTypes = {
  close: PropTypes.func,
  cancel: PropTypes.func,
  desc: PropTypes.string,
  title: PropTypes.string,
  width: PropTypes.string,
  imgSrc: PropTypes.string,
  height: PropTypes.string,
  subTitle: PropTypes.string,
  iconClass: PropTypes.string,
  splitWith: PropTypes.string,
  confirmation: PropTypes.func,
  hideCloseIcon: PropTypes.bool,
  subTitleStyle: PropTypes.string,
  titleExtraStyle: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  cancelButtonTitle: PropTypes.string,
  specialClassInner: PropTypes.string,
  specialClassWrapper: PropTypes.string,
  specialClassContent: PropTypes.string,
  children: PropTypes.element.isRequired,
  specialBackgroundColor: PropTypes.string,
  confirmationButtonTitle: PropTypes.string,
  specialModalButton: PropTypes.string,
  specialActionSheetClassWrapper: PropTypes.string,
};
export default Modal;
