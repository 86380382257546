import { useEffect } from 'react';
import PropTypes from 'prop-types';
import usePrevious from 'custom-hooks/use-prev';

const ScrollToTop = ({ location, children }) => {
  const prevLocation = usePrevious(location);
  useEffect(() => {
    if (location !== prevLocation) {
      window.scrollTo(0, 0);
    }
  }, [location, prevLocation]);

  return children;
};

ScrollToTop.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ScrollToTop;
