import { ACCESS_LOGGED_IN_USER, ACCESS_LOGGED_OUT_USER, ACCESS_GLOBAL } from 'variables';
import SimReplacementRouts from 'pages/replace-sim/routes';
import NafathRoutes from 'pages/nafath/routes';

const pages = [
  {
    path: `home`,
    componentPath: 'home',
    template: 'DashboardLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `verify-auth`,
    componentPath: 'verify-auth-service',
    template: 'DashboardLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `activation-type-selection`,
    componentPath: 'home/activation',
    template: 'ActivationLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `login`,
    componentPath: 'signin-signup/signin',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `login/login-otp`,
    componentPath: 'signin-signup/signin/steps/otp-step',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `login/registered-otp`,
    componentPath: 'signin-signup/signin/steps/registered-otp-step',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `registration`,
    componentPath: 'signin-signup/signup/steps/id-verification',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `registration/road-block`,
    componentPath: 'signin-signup/signup/steps/road-block',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `registration/registration-otp`,
    componentPath: 'signin-signup/signup/steps/otp-step',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `registration/select-number`,
    componentPath: 'signin-signup/signup/steps/select-number',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `registration/account-email`,
    componentPath: 'signin-signup/signup/steps/account-email',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `registration/account-password`,
    componentPath: 'signin-signup/signup/steps/account-password',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `registration/registration-status`,
    componentPath: 'signin-signup/signup/steps/registration-status',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `forget-password`,
    componentPath: 'forget-password',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `forget-password/forget-password-otp`,
    componentPath: 'forget-password/steps/otp-step',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `forget-password/check-email`,
    componentPath: 'forget-password/steps/check-email',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `forget-password/create-password`,
    componentPath: 'forget-password/steps/create-password',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `login-password`,
    componentPath: 'login-password',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },

  {
    path: `plan_selection`,
    componentPath: 'onboarding/select-plan',
    template: 'OnboardingHomepageLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `plan_selection/postpaid`,
    componentPath: 'onboarding/select-plan',
    template: 'OnboardingHomepageLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `plan_selection/prepaid`,
    componentPath: 'onboarding/select-plan',
    template: 'OnboardingHomepageLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `shop/:step/onboarding`,
    componentPath: 'onboarding',
    template: 'OnboardingLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `activate-sim/:step/activate-sim`,
    componentPath: 'activate-sim',
    template: 'ActivationLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `payment`,
    componentPath: 'onboarding',
    template: 'BlankLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `dashboard`,
    componentPath: 'dashboard',
    template: 'DashboardLayout',
    accessType: ACCESS_LOGGED_IN_USER,
  },
  {
    path: `dashboard/:step/dashboard`,
    componentPath: 'dashboard',
    template: 'DashboardLayout',
    accessType: ACCESS_LOGGED_IN_USER,
  },
  {
    path: `pregress-steps`,
    componentPath: 'pregress-steps-test',
    template: 'BlankLayout',
    accessType: ACCESS_GLOBAL,
  },

  {
    path: `order-status/id-number`,
    componentPath: 'order-status/id-number',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `order-status/orders-list`,
    componentPath: 'order-status/order-list',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `order-status/no-orders-found`,
    componentPath: 'order-status/no-orders-found',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `registration/password`,
    componentPath: 'signin-signup/signup/steps/password',
    template: 'SigninLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `dashboard/absher/unregistered`,
    componentPath: 'absher/unregistered',
    template: 'DashboardLayout',
    accessType: ACCESS_LOGGED_IN_USER,
  },
  {
    path: `dashboard/absher/registered`,
    componentPath: 'absher/registered',
    template: 'DashboardLayout',
    accessType: ACCESS_LOGGED_IN_USER,
  },
  {
    path: `pay-your-invoice`,
    componentPath: 'logged-out-users/postpaid/pay-your-invoice',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `recharge-number`,
    componentPath: 'logged-out-users/prepaid/recharge-your-number',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `recharge-number-type`,
    componentPath: 'logged-out-users/prepaid/recharge-your-number-type',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `logged-out-payment-status`,
    componentPath: 'logged-out-users/payment-status',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `payment-voucher-verification`,
    componentPath: 'logged-out-users/prepaid/voucher-verification',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `logged-out-recharge-status`,
    componentPath: 'logged-out-users/prepaid/recharge-status',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `bill-details`,
    componentPath: 'logged-out-users/postpaid/bill-details',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `bill-payment-status`,
    componentPath: 'logged-out-users/postpaid/payment-status',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `pay-invoice-errors`,
    componentPath: 'logged-out-users/postpaid/pay-invoice-errors',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `pay-with-account-id`,
    componentPath: 'logged-out-users/postpaid/pay-with-account-id',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `logged-out-users-checkout`,
    componentPath: 'logged-out-users/checkout',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `store-check-status`,
    componentPath: 'e-commerce',
    template: 'ActivationLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `store/:step/ecommerce`,
    componentPath: 'e-commerce/e-commerce-wrapper.js',
    template: 'ActivationLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `transfer-ownership-option`,
    componentPath: 'transfer-ownership/transfer-ownership-options',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `transfer/:step/ownership`,
    componentPath: 'transfer-ownership/request/transfer-request-wrapper',
    template: 'TransferOwnershipRequestLayout',
    accessType: ACCESS_LOGGED_IN_USER,
  },
  {
    path: `transfer/:step/accept-ownership`,
    componentPath: 'transfer-ownership/accept/transfer-accept-wrapper',
    template: 'TransferOwnershipAcceptLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `store-check-status`,
    componentPath: 'e-commerce',
    template: 'ActivationLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `store/:step/ecommerce`,
    componentPath: 'e-commerce/e-commerce-wrapper.js',
    template: 'ActivationLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `maintenance-mode`,
    componentPath: 'maintenance-mode',
    template: 'BlankLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `renew-plan-number`,
    componentPath: 'logged-out-users/renew-plan/renew-plan-number',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `renew-plan-otp-verification`,
    componentPath: 'logged-out-users/renew-plan/otp-step',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `renew-plan-type`,
    componentPath: 'logged-out-users/renew-plan/renew-plan-type',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  {
    path: `renew-plan-status`,
    componentPath: 'logged-out-users/renew-plan/renewal-status',
    template: 'LoggedOutFlowsLayout',
    accessType: ACCESS_LOGGED_OUT_USER,
  },
  ...SimReplacementRouts,
  ...NafathRoutes,
];
export default pages;
