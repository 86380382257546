import ReactGA from 'react-ga';
import { useTranslation } from 'react-i18next';
import { useLocation, useHistory } from 'react-router-dom';
import { useError } from 'stores/error.context';
import { useEffect, useCallback, useReducer, useMemo, memo, useContext, useState } from 'react';
import Spinner from 'components/shared/spinner';
import ScrollToTop from 'components/shared/scroll-top';
import DownloadAppBanner from 'components/shared/download-app-banner';
import {
  getStorageElement,
  setStorageElement,
  removeStorageElement,
} from 'services/storage.service';
import Layout from 'layout';
import { initTracking, whenUserLoadsPage } from 'services/events.service';
import NetworkDetector from 'services/NetworkDetector';
import { initiateUser, checkAppConfig, clearEcommerceSDKData } from 'services/user.service';
import { getDirection, getDefaultLanguage, promiseHandler, getPathLastPart } from 'utility';
import UserContext from 'stores/user.context';
import Routes from 'routes';
import i18next from 'i18next';
import pages from 'routes/pages';
import {
  START_API,
  SUCCESS_API,
  FAILED_API,
  LOGGED_OUT_USER,
  ACCESS_LOGGED_IN_USER,
  ACCESS_LOGGED_OUT_USER,
} from 'variables';
import './assets/scss/common.scss';
import classes from './style.module.scss';
import { clearPaymentDataFromStorage } from './services/payment.service';
import ErrorPopup from './components/shared/Error-Popup';

const initState = {
  loading: true,
  error: null,
};

const appReducer = (state, action) => {
  switch (action.type) {
    case START_API:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SUCCESS_API:
      return {
        ...state,
        loading: false,
        error: null,
      };
    case FAILED_API:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    default:
      return state;
  }
};

const redirectUrl = () => {
  window.location = `${window.location.origin}/${i18next.language}`;
};

let template = null;
let templateConfig = null;

const App = memo(() => {
  const history = useHistory();
  const location = useLocation();
  const { i18n } = useTranslation();
  const { showError } = useError();
  const { setUserInfo } = useContext(UserContext);
  const [appState, dispatch] = useReducer(appReducer, initState);
  const [isMaintenanceModeOn, setIsMaintenanceModeOn] = useState(false);
  const path = useMemo(() => getPathLastPart(location.pathname), [location.pathname]);
  const currentLang = useMemo(() => i18n.language || getDefaultLanguage(), [i18n.language]);
  const dir = getDirection(currentLang);

  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_APP_ID);

    history.listen(() => {
      whenUserLoadsPage();
      ReactGA.pageview(window.location.pathname);
    });
  }, [history]);

  useEffect(() => {
    document.documentElement.setAttribute('lang', currentLang);
    const root = document.getElementsByTagName('html')[0];
    root.setAttribute('class', dir);
  }, [currentLang, dir]);

  const loadChatBotJS = (src) => {
    const ref = window.document.getElementsByTagName('script')[0];
    const script = window.document.createElement('script');
    script.src = src;
    script.async = true;
    ref.parentNode.insertBefore(script, ref);
  };

  useEffect(() => {
    console.log('%cRender App ComponentDidMount', 'color: #00a6eb; font-size:16px;');
    console.log(
      '%cRelease #',
      'color: #dcdcdc; font-size:16px;',
      process.env.REACT_APP_RELEASE_VERSION
    );
    initTracking();
  }, []);

  // useEffect(() => {
  //   const handleError = (event) => {
  //     const { message, filename, lineno, colno, error } = event;
  //     // Handle the error and display a pop-up or notification
  //     // setError(`Error message: ${message}\n filename: ${filename}\n Line Number: ${lineno}`);
  //     // You can also log the error for debugging purposes
  //     console.error(`Error message: ${message}\n filename: ${filename}\n Line Number: ${lineno}`);
  //     const errorMsg = {
  //       error: `Error message: ${message}\n filename: ${filename}\n Line Number: ${lineno}`,
  //     };
  //     showError(errorMsg);
  //   };

  //   // Add a global error event listener
  //   window.addEventListener('error', handleError);
  //   return () => {
  //     // Remove the error event listener when the component unmounts
  //     window.removeEventListener('error', handleError);
  //   };
  // }, []);

  useEffect(() => {
    // TODO: MOVE This first part to onboarding wrapper and
    // the second part to eCommerce wrapper
    window.history.pushState(null, null, window.location.pathname);
    if (getStorageElement('onboardingOrder')?.finishedOrder) {
      history.push(`/${i18n.language}/plan_selection/postpaid`);
    } else if (getStorageElement('ecommerce-payment-completed')) {
      clearEcommerceSDKData();
      if (getStorageElement('userLoggedIn', 'cookies')) {
        history.push(`/${i18n.language}/dashboard`);
      } else {
        history.push(`/${i18n.language}/home`);
      }
    } else if (getStorageElement('payment-completed')) {
      clearPaymentDataFromStorage();
      if (getStorageElement('userLoggedIn', 'cookies')) {
        history.push(`/${i18n.language}/dashboard`);
      } else {
        history.push(`/${i18n.language}/home`);
      }
    } else {
      history.goBack();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const generateNewSession = useCallback(async () => {
    removeStorageElement('uuid');
    removeStorageElement('authToken');
    dispatch({ type: START_API });
    await promiseHandler(() => initiateUser());
    dispatch({ type: SUCCESS_API });
    redirectUrl();
  }, []);

  const initiateUserData = useCallback(async () => {
    const [userInfo, error] = await promiseHandler(() => initiateUser());
    if (error && error.code && error.code === 'invalid_session_id') {
      generateNewSession();
      return;
    }

    if (error) {
      dispatch({ type: FAILED_API, error: LOGGED_OUT_USER });
      return;
    }
    setUserInfo(userInfo);
    dispatch({ type: SUCCESS_API });
    // check the user SEMATI activated or not
    if (getStorageElement('semati_activation_required')) {
      history.push(`/${i18n.language}/dashboard/absher/unregistered`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateNewSession, setUserInfo]);

  const initiateAppConfig = useCallback(async () => {
    dispatch({ type: START_API });
    const [config, , , , errorObj] = await promiseHandler(() => checkAppConfig());
    if (config) {
      dispatch({ type: SUCCESS_API });
      setStorageElement('appConfig', config);
      initiateUserData();
      if (config.chat_bot_web && config.chat_bot_web_url) {
        loadChatBotJS(config.chat_bot_web_url);
      }
      if (config.enable_maintenance_mode) {
        setIsMaintenanceModeOn(true);
      }
    }
    if (errorObj) {
      showError(errorObj);
      dispatch({ type: FAILED_API });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initiateUserData]);

  useEffect(() => {
    initiateAppConfig();
  }, [initiateAppConfig]);

  const isBaseUrlPath = useMemo(
    () =>
      location.pathname === '/' ||
      location.pathname === `/${currentLang}` ||
      location.pathname === `/${currentLang}/`,
    [location.pathname, currentLang]
  );
  const layoutTemplate = useMemo(() => {
    const page = pages.find((pg) => {
      if (getPathLastPart(pg.path) === path) {
        return pg;
      }
      return null;
    });

    template = '404';
    if (isMaintenanceModeOn) {
      history.replace(`/${currentLang}/maintenance-mode`);
    } else if (
      (page &&
        page.accessType === ACCESS_LOGGED_IN_USER &&
        !getStorageElement('userLoggedIn', 'cookies')) ||
      (!page && isBaseUrlPath)
    ) {
      template = 'SigninLayout';
      history.replace(`/${currentLang}/home${location.search ? `${location.search}` : ''}`);
    } else if (
      page &&
      page.accessType === ACCESS_LOGGED_IN_USER &&
      !getStorageElement('userLoggedIn', 'cookies')
    ) {
      history.replace(`/${currentLang}/home${location.search ? `${location.search}` : ''}`);
    } else if (
      page &&
      page.accessType === ACCESS_LOGGED_OUT_USER &&
      getStorageElement('userLoggedIn', 'cookies')
    ) {
      history.replace(`/${currentLang}/dashboard${location.search ? `${location.search}` : ''}`);
    } else if (!page) {
      history.replace(`/${currentLang}/404${location.search ? `${location.search}` : ''}`);
    }

    if (page) {
      template = page.template;
      templateConfig = page.templateConfig;
    }

    return (
      <ScrollToTop>
        <DownloadAppBanner />
        <Layout template={appState.loading ? 'loading' : template} templateConfig={templateConfig}>
          {appState.loading ? <Spinner /> : <Routes />}
          <ErrorPopup />
        </Layout>
      </ScrollToTop>
    );
  }, [path, history, appState, currentLang, isBaseUrlPath, location.search]);

  return (
    <div className={`${classes.App} ${classes[dir]}`}>
      <ErrorPopup />
      {layoutTemplate}
    </div>
  );
});

export default NetworkDetector(App);
