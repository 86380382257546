import { promiseHandler } from 'utility';
import { setStorageElement, getStorageElement } from 'services/storage.service';
import {
  getPlans,
  getNationalities,
  getOperaters,
  getSTCCDeliveryTimes,
  getAreas,
  getCities,
  enhancedNumberBooking,
} from 'api/onboarding';

export const initializePlans = async (planType, params) => {
  const type = planType.id === 2 ? 'postpaid' : 'prepaid';
  const [plans, error] = await promiseHandler(() => getPlans(type, params));
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return plans.plans;
};
export const initializePrepaidPlans = async (bundleType) => {
  const [plans, error] = await promiseHandler(() => getPlans('postpaid', bundleType));
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return plans.plans;
};
export const initializePostpaidPlans = async (bundleType) => {
  const [plans, error] = await promiseHandler(() => getPlans('prepaid', bundleType));
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return plans.plans;
};
export const initializeNationalities = async () => {
  const [nationalities, error] = await promiseHandler(() => getNationalities());
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return nationalities.nationalities;
};

export const initializeOperaters = async () => {
  const [operators, error] = await promiseHandler(() => getOperaters());
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return operators.operators;
};

export const initializeAreas = async () => {
  const [areas, error] = await promiseHandler(() => getAreas());
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return areas.home_delivery_areas;
};

export const initializeCities = async (areaId) => {
  const [cities, error] = await promiseHandler(() => getCities(areaId));
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return cities.home_delivery_cities;
};

export const initializeDeliveryTimes = async () => {
  const [times, error] = await promiseHandler(() => getSTCCDeliveryTimes());
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return times.delivery_times;
};

export const initializeCustomNumber = async (phone, nextPage) => {
  const [numbers, error] = await promiseHandler(() => enhancedNumberBooking(phone, nextPage));
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return numbers;
};

export const updateOnboardingOrder = (key, val) => {
  let order = getStorageElement('onboardingOrder');
  if (order) {
    order[key] = val;
  } else {
    order = {
      [key]: val,
    };
  }
  setStorageElement('onboardingOrder', order);
};

export const getStorageAttributeValue = (name, key) => {
  const obj = getStorageElement(name);

  if (obj && Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  }

  // Return a default value or handle the case when the key is not found
  return null; // or throw an error, or return a specific default value
};
