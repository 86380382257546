import { Suspense, memo, lazy } from 'react';
import PropTypes from 'prop-types';
import Spinner from 'components/shared/spinner';

let layout = null;

const SigninSignoutLayout = lazy(() =>
  import(/* webpackChunkName: "Default layout" */ `./signin-signout`)
);
const DashboardLayout = lazy(() =>
  import(/* webpackChunkName: "Dashboard layout" */ `./dashboard`)
);
const TransferOwnershipRequestLayout = lazy(() =>
  import(/* webpackChunkName: "TransferOwnershipRequest layout" */ `./transfer-owneship-request`)
);
const TransferOwnershipAcceptLayout = lazy(() =>
  import(/* webpackChunkName: "TransferOwnershipAccept layout" */ `./transfer-ownership-accept`)
);
const LoggedOutFlowsLayout = lazy(() =>
  import(/* webpackChunkName: "Logged Out Flows layout" */ `./logged-out-flows-layout`)
);
const ReplacementSimLayout = lazy(() =>
  import(/* webpackChunkName: "Dashboard layout" */ `./replace-sim-layout`)
);
const BlankLayout = lazy(() => import(/* webpackChunkName: "Blank layout" */ `./blank`));

const OnboardingLayout = lazy(() =>
  import(/* webpackChunkName: "Onboarding flow layout" */ `./onboarding`)
);

const OnboardingHomepageLayout = lazy(() =>
  import(/* webpackChunkName: "Onboarding homepage layout" */ `./onboarding-homepage`)
);

const ActivationLayout = lazy(() =>
  import(/* webpackChunkName: "Activation layout" */ `./activation`)
);

const NafathLayout = lazy(() =>
  import(/* webpackChunkName: "Activation layout" */ `./nafath-layout`)
);

const Error404Layout = lazy(() => import(/* webpackChunkName: "Blank layout" */ `./404`));

const Layout = memo(({ template, children, templateConfig }) => {
  switch (template) {
    case 'DashboardLayout':
      layout = <DashboardLayout templateConfig={templateConfig}>{children}</DashboardLayout>;
      break;
    case 'TransferOwnershipRequestLayout':
      layout = (
        <TransferOwnershipRequestLayout templateConfig={templateConfig}>
          {children}
        </TransferOwnershipRequestLayout>
      );
      break;
    case 'TransferOwnershipAcceptLayout':
      layout = (
        <TransferOwnershipAcceptLayout templateConfig={templateConfig}>
          {children}
        </TransferOwnershipAcceptLayout>
      );
      break;
    case 'BlankLayout':
      layout = <BlankLayout templateConfig={templateConfig}>{children}</BlankLayout>;
      break;
    case 'SigninLayout':
      layout = (
        <SigninSignoutLayout templateConfig={templateConfig}>{children}</SigninSignoutLayout>
      );
      break;
    case 'ActivationLayout':
      layout = <ActivationLayout>{children}</ActivationLayout>;
      break;
    case 'OnboardingLayout':
      layout = <OnboardingLayout>{children}</OnboardingLayout>;
      break;
    case 'OnboardingHomepageLayout':
      layout = <OnboardingHomepageLayout>{children}</OnboardingHomepageLayout>;
      break;
    case 'NafathLayout':
      layout = <NafathLayout>{children}</NafathLayout>;
      break;
    case 'LoggedOutFlowsLayout':
      layout = <LoggedOutFlowsLayout>{children}</LoggedOutFlowsLayout>;
      break;

    case 'ReplacementSimLayout':
      layout = <ReplacementSimLayout>{children}</ReplacementSimLayout>;
      break;
    case 'loading':
      layout = (
        <div className="app-spinner">
          <Spinner className="spinner" />
        </div>
      );
      break;
    case '404':
      layout = <Error404Layout />;
      break;
    default:
      layout = (
        <SigninSignoutLayout templateConfig={templateConfig}>{children}</SigninSignoutLayout>
      );
  }
  return <Suspense fallback={null}>{layout}</Suspense>;
});

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  template: PropTypes.string,
  templateConfig: PropTypes.string,
};

export default Layout;
