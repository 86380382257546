import { getParams } from 'utility';
import Settings from 'services/settings.service';

export const isMockEnabled = () => JSON.parse(Settings.REACT_APP_MOCKING_ENABLED);

export const getMockVersion = () => {
  const mockv = getParams('mock-v');
  if (mockv) {
    return mockv;
  }
  return 1; // success
};

export const getBaseURL = () => {
  if (isMockEnabled()) {
    return Settings.REACT_APP_API_MOCK_URL;
  }
  return Settings.REACT_APP_API_BASE_URL;
};
