import Cookies from 'js-cookie';

export const removeStorageElement = (key, location = 'local') => {
  if (location === 'local') {
    window.localStorage.removeItem(key);
    return;
  }
  if (location === 'cookies') {
    Cookies.remove(key);
    return;
  }
  sessionStorage.removeItem(key);
};

export const clearStorageElements = () => {
  Cookies.clear();
  sessionStorage.clear();
  window.localStorage.clear();
};

export const setStorageElement = (key, value, location = 'local') => {
  const content = JSON.stringify(value);
  if (location === 'local') {
    window.localStorage.setItem(key, content);
    return;
  }
  if (location === 'cookies') {
    Cookies.set(key, value);
    return;
  }
  sessionStorage.setItem(key, content);
};

export const getStorageElement = (key, location = 'local') => {
  if (location === 'local') {
    return JSON.parse(window.localStorage.getItem(key));
  }
  if (location === 'cookies') {
    return Cookies.get(key);
  }
  return JSON.parse(sessionStorage.getItem(key));
};

export const updateStorageObject = (name, key, val) => {
  let obj = getStorageElement(name);
  if (obj) {
    obj[key] = val;
  } else {
    obj = {
      [key]: val,
    };
  }
  setStorageElement(name, obj);
};

export const getStorageAttributeValue = (name, key) => {
  const obj = getStorageElement(name);

  if (obj && Object.prototype.hasOwnProperty.call(obj, key)) {
    return obj[key];
  }

  // Return a default value or handle the case when the key is not found
  return null; // or throw an error, or return a specific default value
};
