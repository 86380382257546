import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ErrorPopup from 'components/shared/Error-Popup';

export default function (ComposedComponent) {
  const NetworkDetector = (props) => {
    const { t } = useTranslation();
    const [isDisconnected, setIsDisconnected] = useState(false);

    useEffect(() => {
      window.addEventListener('online', () => {
        setIsDisconnected(false);
      });

      window.addEventListener('offline', () => {
        setIsDisconnected(true);
      });
    }, []);

    useEffect(() => {
      if (window.navigator.onLine) {
        setIsDisconnected(false);
      } else {
        setIsDisconnected(true);
      }
    }, []);

    return (
      <div>
        {isDisconnected && (
          <ErrorPopup hideCloseButton errorMsg={t('generic_error_internet_connection_issue')} />
        )}
        <ComposedComponent {...props} />
      </div>
    );
  };

  return NetworkDetector;
}
