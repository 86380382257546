// ErrorContext.js
import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const ErrorContext = createContext();

export function useError() {
  return useContext(ErrorContext);
}

export function ErrorProvider({ children }) {
  const [error, setError] = useState({});

  const showError = (message) => {
    let data = {};
    if (message?.error) {
      data = message;
    } else {
      data.error = message;
    }
    setError(data);
  };

  const clearError = () => {
    setError(null);
  };

  return (
    <ErrorContext.Provider value={{ error, showError, clearError }}>
      {children}
    </ErrorContext.Provider>
  );
}

ErrorProvider.propTypes = {
  children: PropTypes.element.isRequired,
};
