import { isMockEnabled } from 'services/mock.service';
import { getParams } from 'utility';
import HTTP from 'services/http.service';

export const getUserAuth = function (UserData) {
  const config = {
    method: 'post',
    url: '/auth/device',
    data: UserData,
    headers: {
      needAutherization: false,
    },
  };
  return HTTP(config);
};

export const getUserLogin = function (authToken) {
  return new Promise((resolve, reject) => {
    const isLoggedin = getParams('isLoggedin');
    if (isMockEnabled()) {
      if (isLoggedin === '1') {
        setTimeout(
          resolve({
            id: 129,
            first_name: '',
            last_name: '',
            email: 'nisarkhan.11@gmail.com',
            mobile_number: '0577309479',
            legacy_auth: false,
            email_confirmed: true,
            is_registered: true,
            is_main: true,
            plan_name_lok: 'primary_offering_148499912',
            plan_id: '148499912',
            balance: { amount: 0, expiration_time: null },
            bounce_balance: { amount: 0, expiration_time: null },
            balance_details: {
              main: {
                amount: { value: 6594.02, currency: 'generic_currency_sar' },
                expiration_time: '2037-01-01T03:00:00+03:00',
              },
              bonus: {
                total: { value: 45.65, currency: 'generic_currency_sar' },
                items: [
                  {
                    amount: { value: 45.65, currency: 'generic_currency_sar' },
                    expiration_time: '2021-10-15T02:56:38+03:00',
                  },
                ],
              },
            },
            properties: {
              subscription_id: '1010001446228',
              subscription_type: 'prepaid',
              subscription_status: 'active',
              line_type: 'minutes',
            },
            referral: {
              share_url: 'https://refer.lebara.sa/7KQ3t3x',
              dashboard_url: 'https://www.lebara.sa/en/referrer',
            },
            loyalty: { totalPoints: 0, expriedSoon: null },
            auth_token:
              'eyJlbmMiOiJBMTI4Q0JDLUhTMjU2IiwiYWxnIjoiUEJFUzItSFMyNTYrQTEyOEtXIiwicDJjIjoyMzU0LCJwMnMiOiIxN1hIbi03LU9pR0djNmc5dE9Qa3hRIn0.sI--d3nVXI6H7kpjtzm0sOcN9BbSbkcgCDb860PMIbM0S9uyL2K45w.jW0EYO3k5-o3npHxfg5eJw.tjtMndQmnuz0rV7Ket_-rApUcm7gFO3eqyDj4_6eHoCqYqPEmk8JTcNOAOCXn42EdkoWhbCCoXQeaICS9fd0en8JxtLb1RwpJcArSNKX-IlVjIHXQbE6ICZY8IwvfMZ7prCrKoiINb2ePl9lE19QaiZi_TsupJg2l9fse9qI7_c.QGgypY6QwNnyuJAvjAiTCg',
          }),
          5000
        );
      } else {
        setTimeout(reject(new Error({ code: -16, message: 'User is not loggedin' })), 5000);
      }
    } else {
      const config = {
        method: 'get',
        url: '/users/current',
        headers: {
          Authorization: authToken,
        },
      };
      return HTTP(config);
    }
  });
};

export const registration = async (data) => {
  const config = {
    method: 'post',
    url: '/user',
    data,
  };
  return HTTP(config);
};

export const updateUserInfo = async (data) => {
  const config = {
    method: 'patch',
    url: '/user',
    data,
  };
  return HTTP(config);
};

export const changeUserPassword = async (data) => {
  const config = {
    method: 'patch',
    url: '/user/change-password',
    data,
  };
  return HTTP(config);
};

export const forgetUserPassword = async (data) => {
  const config = {
    method: 'post',
    url: '/user/forget-password',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const resetUserPassword = async (data) => {
  const config = {
    method: 'post',
    url: '/user/reset-password',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const login = async (data) => {
  const config = {
    method: 'post',
    url: '/users/sign_in',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const resendOtp = function (data) {
  const config = {
    method: 'post',
    url: '/otp/resend',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const verifyOtp = function (data) {
  const config = {
    method: 'post',
    url: '/otp/verify',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const signUpOTP = function (data) {
  const config = {
    method: 'post',
    url: '/users/sign_up_otp',
    data,
  };
  return HTTP(config);
};

/// ///////////////////////////////////////
export const verifySignUpOTP = function (data) {
  const config = {
    method: 'post',
    url: '/users/sign_up_otp_verify',
    data,
  };
  return HTTP(config);
};

/// //////////////////////////////////////
export const guestLogin = function (data) {
  const config = {
    method: 'post',
    url: '/guests/sign_in',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const isRegistered = function (number) {
  const config = {
    method: 'get',
    url: `/users/is_registered?mobile_number=${number}`,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const verifyGuestOtp = function (data) {
  const config = {
    method: 'post',
    url: '/guests/verify',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const verifyRegisteredOtp = function (data) {
  const config = {
    method: 'post',
    url: '/users/verify',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const intializeOrdersList = function (nationalityId) {
  const config = {
    method: 'get',
    url: `/deliveries/requests?nationality_id=${nationalityId}`,
  };
  return HTTP(config);
};

export const getCurrentBill = function () {
  const config = {
    method: 'get',
    url: `/users/current_bill`,
  };
  return HTTP(config);
};

export const isDataSimEligible = function () {
  const config = {
    method: 'get',
    url: `/datasims/is_eligible `,
  };
  return HTTP(config);
};

export const getProfileData = function () {
  const config = {
    method: 'get',
    url: `/users/profile`,
  };
  return HTTP(config);
};

export const blockSpamSms = function (data) {
  const config = {
    data,
    method: 'post',
    url: `/users/spam`,
  };
  return HTTP(config);
};

export const verifyBlockSpamOTP = function (otp, reference) {
  const config = {
    method: 'post',
    url: `/users/spam_otp?otp=${otp}&reference=${reference}`,
  };
  return HTTP(config);
};

export const getUserSematiRegister = function (data) {
  const config = {
    method: 'post',
    url: `/users/register_number_on_semati`,
    data,
  };
  return HTTP(config);
};

export const changePrimaryPhoneNumber = function (data) {
  const config = {
    method: 'patch',
    url: `/users/profile/primary_number`,
    data,
  };
  return HTTP(config);
};

export const changeAlternativePhoneNumber = function (data) {
  const config = {
    method: 'patch',
    url: `/users/profile/alternative_number`,
    data,
  };
  return HTTP(config);
};

export const verifyAlternativeOtp = function (data) {
  const config = {
    method: 'post',
    url: `/users/profile/alternative_number_otp`,
    data,
  };
  return HTTP(config);
};

export const changeEmail = function (data) {
  const config = {
    method: 'patch',
    url: `/users/profile/email`,
    data,
  };
  return HTTP(config);
};
export const verifyEmailOtp = function (data) {
  const config = {
    method: 'post',
    url: `/users/profile/email_otp`,
    data,
  };
  return HTTP(config);
};

export const getManageSims = function (data) {
  const config = {
    method: 'get',
    url: `/users/manage_sims`,
    data,
  };
  return HTTP(config);
};

export const changePassword = function (data) {
  const config = {
    method: 'patch',
    url: `users/passwords`,
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const forgetPasswordOtp = function (data) {
  const config = {
    method: 'post',
    url: '/users/passwords/forgot',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const verifyForgetPasswordOtp = function (data) {
  const config = {
    method: 'post',
    url: '/users/passwords/otp_verify',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const resetPassword = function (data) {
  const config = {
    method: 'post',
    url: 'users/passwords/reset',
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const forgetOrderPasswordOtp = function (data, orderId) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/profiles/forgot`,
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const verifyForgetOrderPasswordOtp = function (data, orderId) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/profiles/otp_verify`,
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const resetOrderPassword = function (data, orderId) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/profiles/reset`,
    data,
  };
  return HTTP(config);
};
/// //////////////////////////////////////
export const getAvatars = function () {
  const config = {
    method: 'get',
    url: '/users/profile/avatars',
  };
  return HTTP(config);
};

/// //////////////////////////////////////
export const updateAvatar = function (id) {
  const config = {
    method: 'post',
    url: `/users/profile?avatar_id=${id}`,
  };
  return HTTP(config);
};

export const getSliderPages = function (lang = 'en') {
  const time = new Date();
  return fetch(
    `${
      process.env.REACT_APP_CMS_BASE_URL
    }/?controller=HomePage&action=get&lang=${lang}&ver=${time?.getTime()}`,
    {
      method: 'get',
    }
  );
};

export const getAppConfig = function () {
  const config = {
    method: 'get',
    url: `/lookup/app_config`,
  };
  return HTTP(config);
};
// Ecommerce
export const startEcommerceSDK = function (data) {
  const config = {
    method: 'post',
    url: `/checkout`,
    data,
  };
  return HTTP(config);
};

export const updateSaleorCheckout = function (data, checkoutId) {
  const config = {
    method: 'post',
    url: `/checkout/${checkoutId}/saleor`,
    data,
  };
  return HTTP(config);
};

export const submitCustomerInfo = function (orderId, data) {
  const config = {
    method: 'post',
    url: `/checkout/${orderId}/customer_info`,
    data,
  };
  return HTTP(config);
};

export const submitDeviceDeliveryInfo = function (orderId, data) {
  const config = {
    method: 'post',
    url: `/checkout/${orderId}/delivery_info`,
    data,
  };
  return HTTP(config);
};

export const getCurrentOrder = function (checkoutType) {
  /* 1 : Additional Data Sim */
  /* 2 : Change Plan */
  /* 4 : eCommerce */
  const config = {
    method: 'get',
    url: `/checkout/current?checkout_type=${checkoutType}`,
  };
  return HTTP(config);
};

export const getCurrentOrderData = function (checkoutId) {
  const config = {
    method: 'get',
    url: `/checkout/${checkoutId}/get`,
  };
  return HTTP(config);
};

export const commitCheckoutOrder = function (orderId, data) {
  const config = {
    method: 'post',
    url: `/checkout/${orderId}/submit`,
    data,
  };
  return HTTP(config);
};

export const generateTempToken = function () {
  const config = {
    method: 'get',
    url: `/users/profile/generate_temp_token`,
  };
  return HTTP(config);
};
