import { ACCESS_GLOBAL } from 'variables';

const SimReplacementRouts = [
  {
    path: `replace`,
    componentPath: 'replace-sim/index.js',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim`,
    componentPath: 'replace-sim/sim',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/failed`,
    componentPath: 'replace-sim/failed',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/recover-number`,
    componentPath: 'replace-sim/recover-number',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/eligibility-id`,
    componentPath: 'replace-sim/eligibility-id',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/eligibility-details`,
    componentPath: 'replace-sim/eligibility-details',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/otp`,
    componentPath: 'replace-sim/otp',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/delivery`,
    componentPath: 'replace-sim/delivery',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/summary`,
    componentPath: 'replace-sim/summary',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/checkout`,
    componentPath: 'replace-sim/checkout',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/error`,
    componentPath: 'replace-sim/error',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/error611`,
    componentPath: 'replace-sim/error611',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/esim-failed`,
    componentPath: 'replace-sim/esim-failed',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/completed`,
    componentPath: 'replace-sim/completed',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/double-auth`,
    componentPath: 'replace-sim/double-auth',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },

  {
    path: `replace/sim/iccid-number`,
    componentPath: 'replace-sim/iccid-number',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/iccid-error`,
    componentPath: 'replace-sim/iccid-error',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/qrcode`,
    componentPath: 'replace-sim/qrcode',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },

  {
    path: `replace/sim/activated`,
    componentPath: 'replace-sim/activated',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/user-password`,
    componentPath: 'replace-sim/password',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/abshir`,
    componentPath: 'replace-sim/abshir',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },

  {
    path: `replace/sim/user-password/forgot`,
    componentPath: 'replace-sim/password/forgot',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/user-password/mobile-otp`,
    componentPath: 'replace-sim/password/mobile-otp',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
  {
    path: `replace/sim/user-password/email-otp`,
    componentPath: 'replace-sim/password/email-otp',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },

  {
    path: `replace/sim/user-password/create-password`,
    componentPath: 'replace-sim/password/create-password',
    template: 'ReplacementSimLayout',
    accessType: ACCESS_GLOBAL,
  },
];
export default SimReplacementRouts;
