import { useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { removeStorageElement, setStorageElement } from 'services/storage.service';
import {
  VERIFIED,
  INIT_CHECKOUT,
  VALID_VOUCHER,
  FILL_USER_INFO,
  SET_IS_REGISTERED,
  PAYMENT_COMPLETED,
  SET_REFERANCE_VALUE,
  SELECT_RECHARGE_OPTION,
  SET_VOUCHER_CODE_VALUE,
  SET_DASHBOARD_ACTIVE_TAB,
  FILL_TRANSFER_OWNERSHIP_DATA,
  REMOVE_TRANSFER_OWNERSHIP_DATA,
  FILL_TRANSFER_OWNERSHIP_OTP_DATA,
  REMOVE_TRANSFER_OWNERSHIP_OTP_DATA,
} from 'variables';

const initialState = {
  userInfo: null,
  verified: false,
  reference: null,
  checkoutId: null,
  isRegistered: false,
  validVoucher: false,
  paymentStatus: null,
  transactionId: null,
  dashboardInfo: null,
  dashboardActiveTab: null,
  selectedVoucherCode: null,
  showBalanceWrapper: false,
  selectedRechargeOption: null,
  transferOwnershipCheckoutObj: null,
  transferOwnershipCheckoutOTPDetails: null,
};

const DashboardReducer = (state, action) => {
  switch (action.type) {
    case FILL_USER_INFO:
      return {
        ...state,
        userInfo: { ...action.userInfo },
      };
    case VERIFIED:
      return {
        ...state,
        verified: action.verified,
      };
    case SET_VOUCHER_CODE_VALUE:
      return {
        ...state,
        selectedVoucherCode: action.code,
      };
    case SELECT_RECHARGE_OPTION:
      return {
        ...state,
        selectedRechargeOption: action.option,
      };
    case VALID_VOUCHER:
      return {
        ...state,
        validVoucher: action.valid,
      };
    case INIT_CHECKOUT:
      return {
        ...state,
        checkoutId: action.checkout_id,
        paymentStatus: action.payment_status,
        transactionId: action.transaction_id,
      };
    case PAYMENT_COMPLETED:
      return {
        ...state,
        paymentStatus: action.payment_status,
      };
    case SET_DASHBOARD_ACTIVE_TAB:
      return {
        ...state,
        dashboardActiveTab: action.dashboard_active_tab,
      };
    case SET_REFERANCE_VALUE:
      return {
        ...state,
        reference: action.reference,
      };
    case SET_IS_REGISTERED:
      return {
        ...state,
        isRegistered: action.isRegistered,
      };
    // TODO: move this part to the transfer ownership redux
    case FILL_TRANSFER_OWNERSHIP_DATA:
      setStorageElement('transferOwnershipCheckoutObj', action.checkout);
      return {
        ...state,
        transferOwnershipCheckoutObj: action.checkout,
      };
    case FILL_TRANSFER_OWNERSHIP_OTP_DATA:
      setStorageElement('transferOwnershipCheckoutOTPDetails', action.details);
      return {
        ...state,
        transferOwnershipCheckoutOTPDetails: action.details,
      };
    case REMOVE_TRANSFER_OWNERSHIP_DATA:
      removeStorageElement('transferOwnershipCheckoutObj');
      return {
        ...state,
        transferOwnershipCheckoutObj: '',
      };
    case REMOVE_TRANSFER_OWNERSHIP_OTP_DATA:
      removeStorageElement('stateObj');
      removeStorageElement('transferOwnershipError');
      removeStorageElement('transferOwnershipCheckoutOTPDetails');
      return {
        ...state,
        transferOwnershipCheckoutOTPDetails: '',
      };
    default:
      return state;
  }
};

const useValue = () => useReducer(DashboardReducer, initialState);

export const {
  Provider: DashboardProvider,
  useTrackedState,
  useUpdate: useDashboardDispatch,
} = createContainer(useValue);
