import { getTimeStamp, isComingFromInformativeWebsite } from 'utility';
import { PREPAID_ID, POSTPAID_ID } from 'variables';
import { getStorageElement, setStorageElement } from './storage.service';
import Tracking from './tracking.service';

const vanityFormater = (id) => {
  if (id === 3) {
    return 'Regular';
  }
  if (id === 4) {
    return 'Silver';
  }
  if (id === 5) {
    return 'Gold';
  }
  if (id === 6) {
    return 'Platinum';
  }
};

const romaingCountriesFormater = (id) => {
  if (id === 1) {
    return 'gcc';
  }
  if (id === 2) {
    return 'Worldwide offers data only';
  }
  if (id === 3) {
    return 'Worldwide offers data & calls';
  }
};

const planManageTabsFormater = (id) => {
  if (id === 1) {
    return 'usage';
  }
  if (id === 2) {
    return 'active plan';
  }
  if (id === 3) {
    return 'statements';
  }
};

const getItemSimType = (item) => {
  if (item?.sim_type === 0 || item?.sim_type === 2) {
    return 'p-sim';
  }
  if (item?.sim_type === 1) {
    return 'e-sim';
  }
};

const getPlanType = (plan) => {
  if (plan?.plan_type === PREPAID_ID) {
    return 'prepaid';
  }
  if (plan?.plan_type === POSTPAID_ID) {
    return 'postpaid';
  }
};

export const getFlow = (source) => {
  if (source === 'onboarding') {
    if (getStorageElement('onboardingOrder').plan_type === PREPAID_ID) {
      return 'prepaid plan';
    }
    return 'postpaid plan';
  }
  if (source === 'dashboard') {
    return 'balance recharge';
  }
};

export const getCardText = (val) => {
  if (val === 1) {
    return 'credit card';
  }
  if (val === 2) {
    return 'mada pay';
  }
  if (val === 3) {
    return 'amex card';
  }
};

export const getActivationType = () => {
  if (getStorageElement('posaDetails')) {
    return 'posa';
  }
  return 'online';
};

export const initTracking = () => {
  Tracking.init();
};

export const getEcommerceObjectForPlan = (plan, index) => {
  const discountAmount = plan.original_price - plan.price;
  const obj = {
    index: index ? index + 1 : 1,
    currency: 'SAR',
    item_id: plan.id,
    price: plan.original_price,
    item_brand: 'salam',
    item_name: plan.title,
    discount: parseFloat(plan.discount_rate) !== 'NaN' ? discountAmount : 0,
    item_list_id: plan?.plan_type?.toString(),
    item_list_name: `${getPlanType(plan)} plan list`,
    item_category: `${getPlanType(plan)} plans`,
    item_category2: getItemSimType(plan),
  };
  return obj;
};

export const setBundlesObject = (listName, item, index) => {
  const object = {
    index: index + 1,
    currency: 'SAR',
    item_id: `${listName.replace(' ', '_')}_${item.id.toString()}`,
    price: item.price,
    item_brand: 'salam',
    item_name: item.title || item.name,
    item_list_name: `${listName} list`,
    item_category: listName,
    item_category2: item.service_type,
  };
  return object;
};

export const getEcommercePlaformObjectForDevice = (device, brandName, index) => {
  const obj = {
    currency: 'SAR',
    item_brand: brandName,
    price: device?.price + device?.vat,
    item_id: device?.product_id,
    index: index ? index + 1 : 1,
    item_name: device?.product_name,
    item_category: device && device?.attributes?.length > 0 ? device?.attributes[0]?.value : '',
    item_category2: device && device?.attributes?.length > 0 ? device?.attributes[1]?.value : '',
  };
  return obj;
};

// Onboarding Flow
export const userViewedOnboardingPlansList = (plans, planType) => {
  const customEventName = 'view_item_list';
  const journeyName = isComingFromInformativeWebsite() ? 'choose_special_number' : planType;
  const onboardingEventsObj = getStorageElement('onboardingEventsObj');
  if (isComingFromInformativeWebsite()) {
    onboardingEventsObj.isComingFromChooseNumber = true;
  }
  setStorageElement('onboardingEventsObj', onboardingEventsObj);
  const items = [];
  plans.forEach((element, index) => {
    const obj = getEcommerceObjectForPlan(element, index);
    items.push(obj);
  });
  const obj = {
    journey_name: journeyName,
    ecommerce: {
      items,
    },
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.eCommerce_event(customEventName, obj);
};

export const userSelectedOnboardingPlan = (item) => {
  const customEventName = 'select_item';
  const items = getEcommerceObjectForPlan(item, 0);
  const obj = {
    journey_name: getStorageElement('onboardingEventsObj')?.isComingFromChooseNumber
      ? 'choose_special_number'
      : getPlanType(item),
    sub_journey_name: item.title,
    ecommerce: {
      items: [items],
    },
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  setStorageElement('onboardingEventsObj', obj);
  Tracking.eCommerce_event(customEventName, obj);
};

export const userViewedOnboardingPlan = () => {
  const customEventName = 'view_item';
  Tracking.eCommerce_event(customEventName, getStorageElement('onboardingEventsObj'));
};

export const addOnboardingPlanToCart = (plan) => {
  const customEventName = 'add_to_cart';
  const eCommerceObj = getEcommerceObjectForPlan(plan);
  const obj = {
    journey_name: plan.plan_type === PREPAID_ID ? 'Prepaid' : 'Postpaid',
    sub_journey_name: plan.title,
    ecommerce: {
      items: [eCommerceObj],
    },
  };
  setStorageElement('onboardingEventsObj', obj);
  Tracking.eCommerce_event(customEventName, obj);
};

export const userBeganOnboardingCheckout = () => {
  const order = getStorageElement('onboardingOrder');
  const eventsObj = getStorageElement('onboardingEventsObj');
  const customEventName = 'begin_checkout';
  if (eventsObj) {
    eventsObj.number_type = order.number_type;
    eventsObj.order_name = order.number_order_type === 0 ? 'new line' : 'switch to salam mobile';
    if (order.number_order_type === 0) {
      eventsObj.number_category = vanityFormater(order?.number?.vanity?.id);
    }
  }
  Tracking.eCommerce_event(customEventName, eventsObj);
};

export const userAddedOnboardingShippingInfo = (type) => {
  const customEventName = 'add_shipping_info';
  const eventsObj = getStorageElement('onboardingEventsObj');
  if (eventsObj) {
    eventsObj.shipping_tier = type;
  }
  Tracking.eCommerce_event(customEventName, eventsObj);
};

export const userAddedOnboardingPaymentInfo = () => {
  const customEventName = 'add_payment_info';
  Tracking.eCommerce_event(customEventName, getStorageElement('onboardingEventsObj'));
};

export const userCompletedOnboardingPayment = () => {
  const customEventName = 'purchase';
  const order = getStorageElement('onboardingOrder');
  const onboardingEventsObj = getStorageElement('onboardingEventsObj');
  const appConfig = getStorageElement('appConfig');
  const deliveryFee = appConfig?.delivery_fee;
  if (onboardingEventsObj.ecommerce) {
    onboardingEventsObj.ecommerce.currency = 'SAR';
    onboardingEventsObj.ecommerce.shipping = deliveryFee;
    onboardingEventsObj.ecommerce.tax = appConfig.vat;
    onboardingEventsObj.ecommerce.value = order.fees[order.fees.length - 1].value;
    onboardingEventsObj.ecommerce.transaction_id =
      getStorageElement('transactionId') || getTimeStamp();
  }
  Tracking.eCommerce_event(customEventName, onboardingEventsObj);
};

export const viewItemList = (plan) => {
  const customEventName = 'view_item_list';
  const eCommerceObj = getEcommerceObjectForPlan(plan);
  eCommerceObj.item_category3 = 'posa';
  const obj = {
    journey_name: plan.plan_type === PREPAID_ID ? 'Prepaid' : 'Postpaid',
    sim_collection_type: 'posa',
    ecommerce: {
      items: [eCommerceObj],
    },
  };
  Tracking.eCommerce_event(customEventName, obj);
};

export const userReachedCheckout = () => {
  const plan = getStorageElement('selected_plan');
  const eventName = 'eec.checkout';
  const planPrice = plan.price;
  Tracking.ecommerceCheckout(eventName, planPrice, 6);
};

export const userReachedPayment = () => {
  const eventName = 'eec.purchase';
  Tracking.ecommercePayment(eventName);
};

// Custom Events
export const whenUserClicksOnSalamHeaderFields = (item) => {
  const customEventName = 'navigation_quicklink';
  const obj = {
    navigation_cta_name: item,
  };
  Tracking.custom_event(customEventName, obj);
};

export const planDetailsCTAClicked = (ctaName) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: ctaName,
  };
  if (getStorageElement('posaDetails')) {
    obj.sim_collection_type = 'posa';
  }
  Tracking.custom_event(customEventName, obj);
};

export const planAdditionalDataSimCTAClicked = (journeyName, optionSelected) => {
  const customEventName = 'additional_sim_confirmation';
  const obj = {
    journey_name: journeyName,
    option_selected: optionSelected,
  };
  Tracking.custom_event(customEventName, obj);
};

export const UserClickedCheckoutCTA = () => {
  const customEventName = 'click_cta';
  const ctaName = 'order_summary_checkout';
  const obj = {
    cta_name: ctaName,
  };
  Tracking.custom_event(customEventName, obj);
};

export const simTypeCTAClicked = (optionSelected) => {
  const customEventName = 'choose_sim_type';
  const obj = {
    option_selected: optionSelected,
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const compatibleDevicesLinkClicked = () => {
  const customEventName = 'click_cta';
  const ctaName = 'view esim compatible phones';
  const obj = {
    cta_name: ctaName,
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const compatibleDevicesModalCTAClicked = (journeyName, isFound) => {
  const customEventName = 'click_cta';
  const ctaName = `esim compatible phones:${isFound}`;
  const obj = {
    cta_name: ctaName,
    journey_name: journeyName,
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const orderTypeCTAClicked = (journeyName, optionSelected) => {
  const customEventName = 'choose_order_type';
  const obj = {
    journey_name: journeyName,
    option_selected: optionSelected,
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const numberCTAClicked = (journeyName, optionSelected, numberCategoryId) => {
  const customEventName = 'select_your_number';
  const obj = {
    journey_name: journeyName,
    option_selected: optionSelected,
    number_category: vanityFormater(numberCategoryId),
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const eligibilitySubmitCTAClicked = () => {
  const customEventName = 'account_creation_continue';
  const obj = {
    step_details: 'id number:continue',
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const contactDetailsSubmitCTAClicked = () => {
  const customEventName = 'account_creation_continue';
  const obj = {
    step_details: 'contact_details:continue',
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const userSubmittedOTP = () => {
  const customEventName = 'account_creation_continue';
  const obj = {
    step_details: 'otp_confirmation_continue',
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const userPasswordCTAClicked = () => {
  const customEventName = 'account_creation_continue';
  const obj = {
    step_details: 'create an account continue',
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const userClickedRegisterCTA = () => {
  const customEventName = 'registration_action';
  Tracking.custom_event(customEventName);
};

export const userIDRegistrationCTAClicked = () => {
  const customEventName = 'registration_start';
  Tracking.custom_event(customEventName);
};

export const userClickeHomeScreenActions = (action) => {
  const customEventName = 'navigation_quick_action';
  const obj = {
    navigation_cta_name: action,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickeManageLineActions = () => {
  const customEventName = 'manage_line';
  const obj = {
    cta_name: 'request a new sim or e-sim',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userRegistrationCTAsClicked = (stepName) => {
  const customEventName = 'registration_continue';
  const obj = {
    step_details: stepName,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userRegistrationCompleted = (id) => {
  const customEventName = 'sign_up';
  const obj = {
    user_id: id,
  };
  Tracking.custom_event(customEventName, obj);
};

export const privacyPolicyLinkClicked = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'check our privacy policy',
  };
  Tracking.custom_event(customEventName, obj);
};

export const loginCTAClicked = () => {
  const customEventName = 'login_action';
  Tracking.custom_event(customEventName);
};

export const loginNumberSubmitCTAClicked = () => {
  const customEventName = 'login_start';
  const obj = {
    login_method: 'mobile number',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userLoggedInSuccessfully = (id) => {
  const customEventName = 'login';
  const obj = {
    user_id: id,
    method: 'phone number',
    login_status: 'logged in',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userLoggedInFailed = () => {
  const customEventName = 'login_failure';
  Tracking.custom_event(customEventName);
};

export const userLoggedOutSuccess = () => {
  const customEventName = 'logout_success';
  Tracking.custom_event(customEventName);
};

export const forgetPasswordLinkClicked = () => {
  const customEventName = 'forgot_your_password';
  Tracking.custom_event(customEventName);
};

export const activationCTAClicked = () => {
  const customEventName = 'activation_start';
  Tracking.custom_event(customEventName);
};

export const userClickedStartedSimActivation = () => {
  const customEventName = 'begin_sim_activation';
  Tracking.custom_event(customEventName);
};

export const userSelectedSimActivationType = (type, ctaName) => {
  const customEventName = 'sim_activation_type';
  const obj = {
    sim_collection_type: type,
    cta_name: ctaName,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userSubmittedActivationDetails = () => {
  const customEventName = 'sim_activation_details';
  const obj = {
    cta_name: 'continue',
  };
  if (isComingFromInformativeWebsite()) {
    obj.sub_journey_Name = 'informative_site';
  }
  Tracking.custom_event(customEventName, obj);
};

export const userSelectedSimToActivate = (order) => {
  const customEventName = 'sim_activation_sim_type';
  const obj = {
    sim_type: getItemSimType(order),
    sim_collection_type: 'online',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOrderPasswordScreenCTAs = (ctaName) => {
  const customEventName = 'sim_activation_login_with_number';
  const obj = {
    cta_name: ctaName,
    sim_collection_type: 'online',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedICCIDScreenCTAs = (ctaName) => {
  const customEventName = 'sim_activation_enter_iccid';
  const obj = {
    cta_name: ctaName,
    sim_collection_type: getActivationType(),
  };
  Tracking.custom_event(customEventName, obj);
};

export const userEnteredWrongICCID = (msg) => {
  const customEventName = 'sim_activation_iccid_error';
  const obj = {
    error_msg: msg,
    sim_collection_type: getActivationType(),
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnAbsherVerificationCTA = (ctaName) => {
  const customEventName = 'sim_activation_absher_verify';
  const obj = {
    cta_name: ctaName,
    sim_collection_type: getActivationType(),
  };
  Tracking.custom_event(customEventName, obj);
};

export const userSubmittedAbsherDetails = () => {
  const customEventName = 'sim_activation_absher_details';
  const obj = {
    sim_collection_type: getActivationType(),
  };
  Tracking.custom_event(customEventName, obj);
};

export const userSubmittedAbsherDetailsSuccessed = () => {
  const customEventName = 'sim_activation_successful';
  const obj = {
    sim_collection_type: getActivationType(),
  };
  Tracking.custom_event(customEventName, obj);
};

export const userSubmittedAbsherDetailsFailed = (msg) => {
  const customEventName = 'sim_activation_fail';
  const obj = {
    reason_to_fail: msg,
    sim_collection_type: getActivationType(),
  };
  Tracking.custom_event(customEventName, obj);
};

export const navigationItemLinkClicked = (tab) => {
  const customEventName = 'navigation_main';
  const obj = {
    navigation_cta_name: tab,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnSalamStore = () => {
  const customEventName = 'navigation_footer';
  const obj = {
    navigation_cta_name: 'salam_mobile_store',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnTrackOrderBanner = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'track order view',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnTrackOrderRow = (order) => {
  const customEventName = 'track_order';
  const obj = {
    order_id: order.order_id,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedBoostersViewAllLink = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'view all offers',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedRechargeCTA = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'recharge',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOutOfKSACTA = (name) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: name,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedBundleCountriesList = (id) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: `${romaingCountriesFormater(id)} bundle country list`,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedRoamingMoreInfoCTA = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'view more details by country',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnCountryRow = (country) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: `country:${country}`,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnManageLineOption = (name) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: name,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnEditProfile = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'profile edit',
  };
  Tracking.custom_event(customEventName, obj);
};

export const UserClickedOnProfileSettingCTA = (option) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: `profile settings change: ${option}`,
  };
  Tracking.custom_event(customEventName, obj);
};

export const UserClickOnCloseEditProfileCTA = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'profile change close',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnTerminationStartCTA = () => {
  const customEventName = 'terminate_start';
  Tracking.custom_event(customEventName);
};

export const userCompletedTermination = () => {
  const customEventName = 'terminate_complete';
  Tracking.custom_event(customEventName);
};

export const userClickedOnMyPlanManageTabs = (id) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: planManageTabsFormater(id),
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedSupportAction = (name) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: name,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnConfirmRechargeAmount = () => {
  const customEventName = 'transfer_credit_start';
  const obj = {
    journey_name: 'credit transfer',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userEnteredCreditTransferMobileNumber = () => {
  const customEventName = 'transfer_credit_continue';
  const obj = {
    journey_name: 'credit transfer',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedEditTransferAmountCTA = () => {
  const customEventName = 'edit_transfer_credit_details';
  const obj = {
    journey_name: 'credit transfer',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedConfirmTransferCTA = () => {
  const customEventName = 'transfer_credit_confirm';
  const obj = {
    journey_name: 'credit transfer',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userCompletedCreditTransfer = () => {
  const customEventName = 'transfer_credit_complete';
  const obj = {
    journey_name: 'credit transfer',
  };
  Tracking.custom_event(customEventName, obj);
};

export const otpConfirmed = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'otp confirmation',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedVerifyAbsherChangePlan = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'absher verification:verify your plan change',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userCheckedTermsAndConditionsCheckbox = () => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: 'order summary confirmation',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userSelectedPaymentMethod = (val, source) => {
  const customEventName = 'payment_method_selection';
  const obj = {
    journey_name: getFlow(source),
    payment_method_selected: getCardText(val),
  };
  Tracking.custom_event(customEventName, obj);
};

// Replace Sim Flow

export const userClickedReplaceSimType = (type) => {
  const customEventName = 'request_a_new_sim';
  const obj = {
    cta_name: type,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userChooseSimReplacementOption = (option) => {
  const customEventName = 'replace_your_sim';
  const obj = {
    cta_name: option,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedNumberVerificationPopUpCTAs = (ctaName) => {
  const customEventName = 'verification_required';
  const obj = {
    cta_name: ctaName,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedOnFailedCTAs = (ctaName) => {
  const customEventName = 'cannot_support_your_request';
  const obj = {
    cta_name: ctaName,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userRecoverNumberIsValid = () => {
  const customEventName = 'recover_your_number';
  const obj = {
    cta_name: 'continue',
  };
  Tracking.custom_event(customEventName, obj);
};

// Bundles(Roaming, Boosters, IDD)

export const userLoadeBundlesList = (journeyName, list) => {
  const customEventName = 'view_item_list';
  const items = [];
  list.forEach((element, index) => {
    const obj = setBundlesObject(journeyName, element, index);
    items.push(obj);
  });
  const obj = {
    journey_name: journeyName,
    ecommerce: {
      items,
    },
  };
  Tracking.eCommerce_event(customEventName, obj);
};

export const userSelectedBundle = (journeyName, item) => {
  const customEventName = 'select_item';
  const items = setBundlesObject(journeyName, item, 0);
  const obj = {
    journey_name: journeyName,
    ecommerce: {
      items: [items],
    },
  };
  setStorageElement('bundlesEventsObject', obj);
  Tracking.eCommerce_event(customEventName, obj);
};

export const userViewedBundle = () => {
  const customEventName = 'view_item';
  Tracking.eCommerce_event(customEventName, getStorageElement('bundlesEventsObject'));
};

export const userAddedBundleToCart = () => {
  const customEventName = 'add_to_cart';
  Tracking.eCommerce_event(customEventName, getStorageElement('bundlesEventsObject'));
};

export const userbeganBundleCheckout = () => {
  const customEventName = 'begin_checkout';
  Tracking.eCommerce_event(customEventName, getStorageElement('bundlesEventsObject'));
};

export const userCompletedBundlePurchase = (bundle) => {
  const customEventName = 'purchase';
  const bundlesObject = getStorageElement('bundlesEventsObject');
  const appConfig = getStorageElement('appConfig');
  const deliveryFee = appConfig?.delivery_fee;
  if (bundlesObject.ecommerce) {
    bundlesObject.ecommerce.currency = 'SAR';
    bundlesObject.ecommerce.shipping = deliveryFee;
    bundlesObject.ecommerce.tax = appConfig.vat;
    bundlesObject.ecommerce.value = bundle.price;
    bundlesObject.ecommerce.transaction_id =
      getStorageElement('dashboard_transactionId') || getTimeStamp();
  }
  Tracking.eCommerce_event(customEventName, bundlesObject);
};
// Recharge Flow
export const userViewdRechargeOptions = (options) => {
  const customEventName = 'view_item_list';
  const journeyName = 'balance recharge';
  const subJourneyName = 'recharge your line';
  const items = [];
  options.forEach((element, index) => {
    const obj = setBundlesObject(journeyName, element, index);
    items.push(obj);
  });
  const obj = {
    journey_name: journeyName,
    sub_journey_name: subJourneyName,
    ecommerce: {
      items,
    },
  };
  Tracking.eCommerce_event(customEventName, obj);
};

export const userSelectedRechargeOption = (option) => {
  const customEventName = 'select_item';
  const journeyName = 'balance recharge';
  const subJourneyName = 'recharge your line';
  const items = setBundlesObject(journeyName, option, 0);
  const obj = {
    journey_name: journeyName,
    sub_journey_name: subJourneyName,
    ecommerce: {
      items: [items],
    },
  };
  setStorageElement('rechargeOptionEventsObject', obj);
  Tracking.eCommerce_event(customEventName, obj);
};

export const userViewedRechargeOption = () => {
  const customEventName = 'view_item';
  Tracking.eCommerce_event(customEventName, getStorageElement('rechargeOptionEventsObject'));
};

export const userAddedRechargeOptionToCart = () => {
  const customEventName = 'add_to_cart';
  Tracking.eCommerce_event(customEventName, getStorageElement('rechargeOptionEventsObject'));
};

export const userBeganRechargeOptionCheckout = (val) => {
  const customEventName = 'begin_checkout';
  const obj = getStorageElement('rechargeOptionEventsObject');
  obj.ecommerce.payment_type = getCardText(val);
  Tracking.eCommerce_event(customEventName, obj);
};

export const userAddedRechargePaymentInfo = () => {
  const customEventName = 'add_payment_info';
  Tracking.eCommerce_event(customEventName, getStorageElement('rechargeOptionEventsObject'));
};

export const userCompletedRechargePayment = (rechargeOption) => {
  const customEventName = 'purchase';
  const rechargeOptionEventsObject = getStorageElement('rechargeOptionEventsObject');
  const appConfig = getStorageElement('appConfig');
  const deliveryFee = appConfig?.delivery_fee;
  if (rechargeOptionEventsObject) {
    if (rechargeOptionEventsObject.ecommerce) {
      rechargeOptionEventsObject.ecommerce.currency = 'SAR';
      rechargeOptionEventsObject.ecommerce.shipping = deliveryFee;
      rechargeOptionEventsObject.ecommerce.tax = appConfig.vat;
      rechargeOptionEventsObject.ecommerce.value = rechargeOption.price;
      rechargeOptionEventsObject.ecommerce.transaction_id =
        getStorageElement('dashboard_transactionId') || getTimeStamp();
    }
    Tracking.eCommerce_event(customEventName, rechargeOptionEventsObject);
  }
};

// Change Plan
export const userViewedPlansList = (plans, planType) => {
  if (plans && plans?.length) {
    const customEventName = 'view_item_list';
    const journeyName = `change your plan: ${planType}`;
    const items = [];
    plans.forEach((element, index) => {
      const obj = getEcommerceObjectForPlan(element, index);
      items.push(obj);
    });
    const obj = {
      journey_name: journeyName,
      ecommerce: {
        items,
      },
    };
    Tracking.eCommerce_event(customEventName, obj);
  }
};

export const userSelectedPlan = (item, planText) => {
  const customEventName = 'select_item';
  const items = getEcommerceObjectForPlan(item, 0);
  const obj = {
    journey_name: `change your plan: ${planText}`,
    sub_journey_name: item.title,
    ecommerce: {
      items: [items],
    },
  };
  setStorageElement('changePlanEventsObject', obj);
  Tracking.eCommerce_event(customEventName, obj);
};

export const userViewedPlan = () => {
  const customEventName = 'view_item';
  Tracking.eCommerce_event(customEventName, getStorageElement('changePlanEventsObject'));
};

export const userAddedPlanToCart = () => {
  const customEventName = 'add_to_cart';
  Tracking.eCommerce_event(customEventName, getStorageElement('changePlanEventsObject'));
};

export const userbeganPlanCheckout = () => {
  const customEventName = 'begin_checkout';
  Tracking.eCommerce_event(customEventName, getStorageElement('changePlanEventsObject'));
};

export const userAddedPlanPaymentInfo = () => {
  const customEventName = 'add_payment_info';
  Tracking.eCommerce_event(customEventName, getStorageElement('changePlanEventsObject'));
};

export const userCompletedPlanPurchase = (bundle) => {
  const customEventName = 'purchase';
  const bundlesObject = getStorageElement('changePlanEventsObject');
  const appConfig = getStorageElement('appConfig');
  const deliveryFee = appConfig?.delivery_fee;
  if (bundlesObject.ecommerce) {
    bundlesObject.ecommerce.currency = 'SAR';
    bundlesObject.ecommerce.shipping = deliveryFee;
    bundlesObject.ecommerce.tax = appConfig.vat;
    bundlesObject.ecommerce.value = bundle.price;
    bundlesObject.ecommerce.transaction_id =
      getStorageElement('dashboard_transactionId') || getTimeStamp();
  }
  Tracking.eCommerce_event(customEventName, bundlesObject);
};

export const whenUserLoadsPage = () => {
  const lang = getStorageElement('i18next', 'cookies');
  const url = window.location.href;
  const pageType = url.split('/')[5];

  const obj = {
    event: 'virtualPageview',
    login_status: getStorageElement('userLoggedIn', 'cookies') ? 'logged in' : 'not logged in',
    page_title: 'Salam App',
    page_url: url,
    user_id: getStorageElement('userLoggedIn', 'cookies')
      ? getStorageElement('profileId')
      : getTimeStamp(),
    page_type: pageType || url.split('/')[4],
    user_type: getStorageElement('userLoggedIn', 'cookies')
      ? `${getPlanType(getStorageElement('planDetails'))} user`
      : 'guest user',
    locale: lang,
  };
  Tracking.page_view_event(obj);
};

// Ecommerce Events

export const userClickedonEcommerceBannerCTA = () => {
  const customEventName = 'choose_your_devices';
  const obj = {
    cta_name: 'check all devices',
  };
  Tracking.custom_event(customEventName, obj);
};

export const userClickedonEcommerceContactDeliverySubmitCTA = (ctaName) => {
  const customEventName = 'click_cta';
  const obj = {
    cta_name: ctaName,
  };
  Tracking.custom_event(customEventName, obj);
};

export const userAddedEcommercePlatformShippingInfo = (device) => {
  const customEventName = 'add_shipping_info';
  const deliveryType = 'delivery';
  const paymentType =
    getStorageElement('payment_type', 'cookies') === 'INSTALLMENT'
      ? `installment plan: ${getStorageElement('installment-vendor', 'cookies')}`
      : 'pay full price';
  const extras = device?.extra;
  if (extras) {
    const product = extras?.saleor_checkout?.lines[0]?.variant?.product;
    const brandName =
      product && product?.metadata && product?.metadata.length > 0
        ? product?.metadata[0]?.value
        : '';
    const productType = product?.productType?.name;
    const category = product?.category?.name;
    const eCommerceObj = getEcommercePlaformObjectForDevice(extras, brandName);
    const obj = {
      journey_name:
        `${brandName?.toString()?.toLowerCase()} ${productType?.toString()?.toLowerCase()}` || '',
      sub_journey_name: category?.toString().toLowerCase() || '',
      payment_new_type: paymentType,
      ecommerce: {
        items: [eCommerceObj],
      },
    };
    setStorageElement('eCommerceEventsObj', obj);
    if (obj) {
      obj.shipping_tier = deliveryType;
    }
    Tracking.eCommerce_event(customEventName, obj);
  }
};

export const userAddedEcommercePlatformPaymentInfo = (device) => {
  const customEventName = 'add_payment_info';
  let eCommerceEventsObj = getStorageElement('eCommerceEventsObj');
  if (!eCommerceEventsObj) {
    const extras = device?.extra;
    if (extras) {
      const product = extras?.saleor_checkout?.lines[0]?.variant?.product;
      const brandName =
        product && product?.metadata && product?.metadata.length > 0
          ? product?.metadata[0]?.value
          : '';
      const productType = product?.productType?.name;
      const category = product?.category?.name;
      const eCommerceObj = getEcommercePlaformObjectForDevice(extras, brandName);
      const obj = {
        journey_name:
          `${brandName?.toString()?.toLowerCase()} ${productType?.toString()?.toLowerCase()}` || '',
        sub_journey_name: category?.toString()?.toLowerCase() || '',
        ecommerce: {
          items: [eCommerceObj],
        },
      };
      eCommerceEventsObj = obj;
      setStorageElement('eCommerceEventsObj', obj);
    }
  }
  const cardType = getCardText(getStorageElement('card_type'));
  const paymentMethod =
    getStorageElement('payment_type', 'cookies') === 'INSTALLMENT'
      ? getStorageElement('installment-vendor', 'cookies')
      : cardType;
  if (eCommerceEventsObj?.ecommerce) {
    eCommerceEventsObj.ecommerce.payment_type = paymentMethod;
  }
  Tracking.eCommerce_event(customEventName, eCommerceEventsObj);
};

export const userCompletedEcommercePlatformPayment = () => {
  const customEventName = 'purchase';
  const eCommerceEventsObj = getStorageElement('eCommerceEventsObj');
  const appConfig = getStorageElement('appConfig');
  const deliveryFee = appConfig?.delivery_fee;
  if (eCommerceEventsObj?.ecommerce) {
    eCommerceEventsObj.ecommerce.currency = 'SAR';
    eCommerceEventsObj.ecommerce.shipping = deliveryFee;
    eCommerceEventsObj.ecommerce.tax = appConfig.vat;
    eCommerceEventsObj.ecommerce.value = eCommerceEventsObj?.ecommerce?.items[0]?.price;
    eCommerceEventsObj.ecommerce.transaction_id =
      getStorageElement('transactionId') || getTimeStamp();
  }
  Tracking.eCommerce_event(customEventName, eCommerceEventsObj);
};
