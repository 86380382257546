import { useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { removeStorageElement, setStorageElement } from 'services/storage.service';
import {
  PAYMENT_COMPLETED,
  FILL_TRANSFER_OWNERSHIP_DATA,
  REMOVE_TRANSFER_OWNERSHIP_DATA,
  FILL_TRANSFER_OWNERSHIP_OTP_DATA,
  REMOVE_TRANSFER_OWNERSHIP_OTP_DATA,
  FILL_TRANSFER_OWNERSHIP_CONFIRMATION_SCREEN_DATA,
} from 'variables';

const initialState = {
  isEsim: false,
  orderId: null,
  paymentStatus: null,
  transferOwnershipCheckoutObj: null,
  transferOwnershipConfirmationObj: null,
  transferOwnershipCheckoutOTPDetails: null,
};

const OwnershipTransferReducer = (state, action) => {
  switch (action.type) {
    case PAYMENT_COMPLETED:
      return {
        ...state,
        paymentStatus: action.payment_status,
      };
    case FILL_TRANSFER_OWNERSHIP_DATA:
      setStorageElement('transferOwnershipCheckoutObj', action.checkout);
      return {
        ...state,
        orderId: action.checkout?.order_id,
        isEsim: action.checkout?.plan?.sim_type === 1,
        transferOwnershipCheckoutObj: action.checkout,
      };
    case FILL_TRANSFER_OWNERSHIP_OTP_DATA:
      setStorageElement('transferOwnershipCheckoutOTPDetails', action.details);
      return {
        ...state,
        transferOwnershipCheckoutOTPDetails: action.details,
      };
    case REMOVE_TRANSFER_OWNERSHIP_DATA:
      removeStorageElement('transferOwnershipCheckoutObj');
      return {
        ...state,
        transferOwnershipCheckoutObj: '',
      };
    case REMOVE_TRANSFER_OWNERSHIP_OTP_DATA:
      removeStorageElement('stateObj');
      removeStorageElement('transferOwnershipError');
      removeStorageElement('transferOwnershipCheckoutOTPDetails');
      return {
        ...state,
        transferOwnershipCheckoutOTPDetails: '',
      };
    case FILL_TRANSFER_OWNERSHIP_CONFIRMATION_SCREEN_DATA:
      setStorageElement('transferOwnershipConfirmationObj', action.checkout);
      return {
        ...state,
        transferOwnershipConfirmationObj: action.checkout,
      };
    default:
      return state;
  }
};

const useValue = () => useReducer(OwnershipTransferReducer, initialState);

export const {
  Provider: OwnershipTransferProvider,
  useTrackedState,
  useUpdate: useOwnershipTransferDispatch,
} = createContainer(useValue);
