/* HTTP service */
import md5 from 'md5';
import axios from 'axios';
import i18next from 'i18next';
import { initiateUser } from 'services/user.service';
import { isMockEnabled, getMockVersion, getBaseURL } from 'services/mock.service';
import { getStorageElement } from './storage.service';

let device = '';
let authToken = '';
const headers = {
  charset: 'utf-8',
  'Salam-Platform': 'web',
  'Content-Type': 'application/json',
  'Salam-Installed-App-Version': '194',
  'x-protocol-version': `v${process.env.REACT_APP_API_VERSION}`,
};
const baseUrl = getBaseURL();
const HTTP = axios.create({
  baseURL: baseUrl,
  headers,
});

HTTP.defaults.headers.common.Accept = 'application/json';

HTTP.interceptors.request.use(
  (config) => {
    if (isMockEnabled()) {
      config.headers['Mock-Version'] = getMockVersion();
    }
    if (device) {
      config.headers['Device-Id'] = device;
      config.headers['Trace-Id'] = md5(device);
    }
    config.headers['accept-language'] = i18next.language;
    if (config.headers.needAutherization === undefined) {
      config.headers.Authorization = getStorageElement('userLoggedIn', 'cookies')
        ? getStorageElement('loggedInAuthToken', 'cookies')
        : getStorageElement('authToken');
    }
    headers['accept-language'] = i18next.language;
    headers.Authorization = getStorageElement('userLoggedIn', 'cookies')
      ? getStorageElement('loggedInAuthToken', 'cookies')
      : getStorageElement('authToken');
    delete config.headers.needAutherization;
    return config;
  },
  (error) => Promise.reject(error)
);

HTTP.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalRequest = error.config;
    if (
      (error.response.data.code === -203 || error.response.data.code === -205) &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      initiateUser(true);
    }
    return Promise.reject(error);
  }
);

HTTP.get_device_id = () => device;

export const setDeviceId = (val) => {
  device = val;
};

export const setSession = (val) => {
  authToken = val;
};

export const getSession = () => authToken;

export const getHeaders = () => headers;

export default HTTP;
