import { useReducer } from 'react';
import { createContainer } from 'react-tracked';
import Settings from 'services/settings.service';
import {
  setStorageElement,
  getStorageElement,
  removeStorageElement,
} from 'services/storage.service';
import { updateOnboardingOrder } from 'services/onboarding.service';
import {
  SET_CURRENT_STATE,
  INIT_ORDER_ID,
  SELECT_PLAN,
  SELECT_PLAN_TYPE,
  SELECT_ORDER_TYPE,
  SELECT_SIM_TYPE,
  ESIM,
  SELECT_NUMBER,
  UNSELECT_NUMBER,
  FILL_ID_INFO,
  FILL_CONTACT_INFO,
  VERIFIED,
  SUMMARY_CHECKED,
  DELIVERY_ADDRESS_INFO,
  SET_ONBOARDING_FLOW,
  INIT_CHECKOUT,
  PAYMENT_COMPLETED,
  HIDE_ORDER_COUNTER,
  SHOW_ORDER_COUNTER,
  RESET_ORDER_COUNTER,
  SELECTED_DELIVERY_AREA,
  RESET_ORDER,
  SELECT_FLOW_TYPE,
  SELECT_ONBOARDING_FLOW_TYPE,
  SELF_ACTIVATION_TYPE,
  SELF_ACTIVATION_TYPE_2,
  SCHEDULE_COURIER_MODULE_OPENED,
} from 'variables';

const initialState = {
  flowType: '',
  currentState: '',
  currentStateStack: [],
  plansType: { id: 2, label: 'postpaid' },
  isPlanSelected: false,
  isPlanTypeSelected: false,
  isNumberSelected: false,
  isEligible: false,
  isPlanPaid: false,
  isEsim: false,
  isVerified: false,
  iSummaryViewed: false,
  isCheckoutViewed: false,
  isOrderTypeSelected: false,
  isDeliveryAddressFilled: false,
  selectedPlan: null,
  requireDataSim: null,
  skippedSimTypeSelection: null,
  orderType: null, // orderSim | MNP
  selectedNumber: null,
  selectedMNP: null,
  idInfo: null,
  contactInfo: null,
  deliveryType: '', // delivery | pickup
  addressDelivery: null,
  orderId: null,
  fees: null,
  checkoutId: null,
  paymentStatus: null,
  transactionId: null,
  remainingCounterTime: null,
  showOrderCounter: false,
  area: null,
  isSelfActivation: false,
  isScheduleCourierModalOpen: false,
};
// TODO: MOVE THIS TO ENVIRONMENT
const currentStepsOrder = {
  plan_selection: 0,
  sim_type_selection: 1,
  number_order_type_selection: 2,
  number_selection: 3,
  mnp_info: 3,
  eligibility_check: 4,
  account_details: 5,
  'not-eligible': 6,
  account_verification: 6,
  account_password: 7,
  delivery_details: 8,
  payment: 9,
  'payment-form': 10,
  'payment-verification': 11,
};
const dataSimCurrentStepsOrder = {
  plan_selection: 0,
  eligibility_check: 1,
  account_details: 2,
  'not-eligible': 3,
  account_verification: 3,
  account_password: 4,
  delivery_details: 5,
  payment: 6,
  'payment-form': 7,
  'payment-verification': 8,
};
const OwnershipTransferStepsOrder = {
  sim_type_selection: 0,
  eligibility_check: 1,
  account_details: 2,
  account_password: 3,
  delivery_details: 4,
  payment: 5,
  'payment-form': 6,
  'payment-verification': 7,
};

const OnboardingReducer = (state, action) => {
  switch (action.type) {
    case RESET_ORDER:
      removeStorageElement('posaDetails');
      removeStorageElement('onboardingOrder');
      removeStorageElement('onboardingEventsObj');
      return {
        ...state,
        isEsim: null,
        orderId: null,
        orderType: null,
        currentState: '',
        isDataSim: false,
        requireDataSim: null,
        isSelfActivation: false,
        skippedSimTypeSelection: null,
      };
    case RESET_ORDER_COUNTER:
      return {
        ...state,
        remainingCounterTime: null,
      };
    case SET_CURRENT_STATE:
      updateOnboardingOrder('currentState', action.step);
      if (action.step === 'number_selection') {
        const newArray = [...Settings.format.currentStateStack];
        newArray[currentStepsOrder.number_selection] = 'number_selection';
        Settings.format.currentStateStack = newArray;
        updateOnboardingOrder('currentStateStack', Settings.format.currentStateStack);
      } else if (action.step === 'mnp_info') {
        const newArray = [...Settings.format.currentStateStack];
        newArray[currentStepsOrder.number_selection] = 'mnp_info';
        Settings.format.currentStateStack = newArray;
        updateOnboardingOrder('currentStateStack', Settings.format.currentStateStack);
      } else if (action.step === 'not-eligible') {
        if (state.isDataSim) {
          const newArray = [...Settings.format.dataSimCurrentStateStack];
          newArray[dataSimCurrentStepsOrder.account_details] = 'not-eligible';
          Settings.format.dataSimCurrentStateStack = newArray;
          updateOnboardingOrder('currentStateStack', Settings.format.dataSimCurrentStateStack);
        } else {
          const newArray = [...Settings.format.currentStateStack];
          newArray[currentStepsOrder.account_details] = 'not-eligible';
          Settings.format.currentStateStack = newArray;
          updateOnboardingOrder('currentStateStack', Settings.format.currentStateStack);
        }
      } else if (state.flowType === 'ownership_transfer') {
        updateOnboardingOrder('currentStateStack', Settings.format.ownerShipTransferStack);
      } else if (!state.isDataSim) {
        updateOnboardingOrder('currentStateStack', Settings.format.currentStateStack);
      } else {
        updateOnboardingOrder('currentStateStack', Settings.format.dataSimCurrentStateStack);
      }
      updateOnboardingOrder(
        'currentStateCounter',
        state.isDataSim
          ? dataSimCurrentStepsOrder[action.step]
          : [
              state.flowType === 'ownership_transfer'
                ? OwnershipTransferStepsOrder[action.step]
                : currentStepsOrder[action.step],
            ]
      );
      return {
        ...state,
        currentState: action.step,
        currentStateStack: !state.isDataSim
          ? Settings.format.currentStateStack
          : Settings.format.dataSimCurrentStateStack,
      };
    case INIT_ORDER_ID:
      return {
        ...state,
        orderId: action.orderId,
        currentState: 'plan_selection',
      };
    case SELECT_FLOW_TYPE:
      setStorageElement('posaDetails', {
        isSelfActivation:
          action.flowType === SELF_ACTIVATION_TYPE || action.flowType === SELF_ACTIVATION_TYPE_2,
        ...getStorageElement('posaDetails'),
      });
      return {
        ...state,
        isSelfActivation: action.flowType,
      };
    case SELECT_ONBOARDING_FLOW_TYPE:
      return {
        ...state,
        flowType: action.flowType,
      };
    case SELECT_PLAN_TYPE:
      removeStorageElement('payment-completed');
      updateOnboardingOrder('currentStateCounter', 0);
      updateOnboardingOrder('currentStateStack', ['plan_selection']);
      return {
        ...state,
        plansType: {
          id: action.plan && action.plan.id,
          label: action.plan && action.plan.label && action.plan.label.toLowerCase(),
        },
      };
    case SELECT_PLAN:
      updateOnboardingOrder(
        'skippedSimTypeSelection',
        action.currentStep === 'number_order_type_selection' || false
      );
      return {
        ...state,
        isPlanSelected: true,
        selectedPlan: action.plan,
        currentState: action.currentState,
        requireDataSim: action.requireDataSim,
        isDataSim: action?.plan?.bundle_type === 1,
        selectedNumber: action?.number || '',
        skippedSimTypeSelection: action.currentStep === 'number_order_type_selection' || false,
      };
    case SELECT_ORDER_TYPE:
      return {
        ...state,
        orderType: action.orderType,
        isOrderTypeSelected: true,
        currentState: action.currentState,
      };
    case SELECT_SIM_TYPE:
      // setStorageElement('sim_or_esim', action.simType === ESIM ? 'esim' : 'sim');
      return {
        ...state,
        isEsim: action.simType === ESIM,
      };
    case SELECT_NUMBER:
      if (action.operator) {
        setStorageElement('operater', action.operator);
        return {
          ...state,
          selectedNumber: action.selectedNumber,
          isNumberSelected: true,
          operator: action.operator,
          selectedMNP: action.selectedNumber,
          remainingCounterTime: action.expiresAt,
          currentState: action.currentState,
        };
      }
      return {
        ...state,
        selectedNumber: action.selectedNumber,
        selectedMNP: null,
        isNumberSelected: true,
        currentState: action.currentState,
        remainingCounterTime: action.expiresAt,
      };
    case UNSELECT_NUMBER:
      return {
        ...state,
        selectedNumber: null,
        isNumberSelected: false,
        currentState: action.currentState,
      };
    case FILL_ID_INFO:
      return {
        ...state,
        idInfo: { ...action.idInfo },
        currentState: action.currentState,
      };
    case FILL_CONTACT_INFO:
      return {
        ...state,
        contactInfo: { ...action.contactInfo },
        currentState: action.currentState,
      };
    case VERIFIED:
      return {
        ...state,
        isVerified: action.verified,
        currentState: action.currentState,
      };
    case SELECTED_DELIVERY_AREA:
      return {
        ...state,
        area: action.area,
      };
    case SCHEDULE_COURIER_MODULE_OPENED:
      return {
        ...state,
        isScheduleCourierModalOpen: action.value,
      };
    case DELIVERY_ADDRESS_INFO:
      return {
        ...state,
        isDeliveryAddressFilled: true,
        address: action.address,
        lat: action.lat,
        lng: action.lng,
        currentState: action.currentState,
      };
    case SET_ONBOARDING_FLOW: {
      const plan = action.order ? action.order.plan : null;
      const isPlanSelected = Boolean(plan);
      const isSelfActivation = action.order
        ? action.order.flow_type === SELF_ACTIVATION_TYPE
        : false;
      Object.entries(action.order).forEach(([key, value]) => {
        updateOnboardingOrder(key, value);
      });
      if (getStorageElement('posaDetails')) {
        setStorageElement('posaDetails', {
          isSelfActivation,
          ...getStorageElement('posaDetails'),
        });
      }
      const isOrderTypeSelected = action?.order?.number_order_type === 0 ? 'newLine' : 'MNP';
      if (action.order) {
        const order = action.order;
        return {
          ...state,
          isPlanSelected,
          fees: order.fees,
          orderId: order.order_id,
          selectedPlan: order.plan,
          selectedNumber: order.number,
          selectedMNP: order.mnp_number,
          orderType: isOrderTypeSelected,
          currentState: order.current_state,
          isEsim: order.sim_type === 1 || false,
          requireDataSim: order.require_data_sim,
          isSelfActivation,
          flowType: order?.flow_type,
          isDataSim: order?.plan?.bundle_type === 1,
          isNumberSelected: Boolean(order.number),
          remainingCounterTime: order.number ? order.number.expires_at : null,
          skippedSimTypeSelection: getStorageElement('onboardingOrder')?.skippedSimTypeSelection,
          contactInfo: action.order
            ? {
                email: order.email,
                mobile: order.mobile_number,
              }
            : null,
        };
      }
      break;
    }
    case SUMMARY_CHECKED:
      return {
        ...state,
        iSummaryViewed: true,
        checkoutId: action.checkout_id,
        paymentStatus: action.payment_status,
        transactionId: action.transaction_id,
      };
    case INIT_CHECKOUT:
      return {
        ...state,
        isCheckoutViewed: true,
        checkoutId: action.checkout_id,
        paymentStatus: action.payment_status,
        transactionId: action.transaction_id,
      };
    case PAYMENT_COMPLETED:
      return {
        ...state,
        isPlanPaid: Boolean(action.payment_status),
        paymentStatus: action.payment_status,
      };
    case HIDE_ORDER_COUNTER:
      return {
        ...state,
        showOrderCounter: action.showOrderCounter,
      };
    case SHOW_ORDER_COUNTER:
      return {
        ...state,
        showOrderCounter: action.showOrderCounter,
      };
    default:
      return state;
  }
};

const useValue = () => useReducer(OnboardingReducer, initialState);

export const {
  Provider: OnboardingProvider,
  useTrackedState,
  useUpdate: useDispatch,
} = createContainer(useValue);
