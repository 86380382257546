import { useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { setStorageElement, removeStorageElement } from 'services/storage.service';

import {
  FILL_USER_INFO,
  FILL_SIM_ACTIVATION_INFO,
  SELECT_ORDER,
  SET_ORDERS_LIST,
  VERIFIED,
  SET_ACTIVATION_STATUS,
  ACTIVATION_FLOW_DONE,
  SET_IAM_TOKEN,
  FILL_ICCID,
} from 'variables';

const initialState = {
  iccid: null,
  simInfo: null,
  userInfo: null,
  iamToken: null,
  verified: false,
  selectedOrder: {},
  activationInfo: null,
  onboardingOrders: {},
  activationStatus: null,
};

const ActivationReducer = (state, action) => {
  switch (action.type) {
    case FILL_USER_INFO:
      setStorageElement('userInfo', { ...action.userInfo });
      return {
        ...state,
        userInfo: { ...action.userInfo },
      };
    case SET_ORDERS_LIST: {
      const orders = action.onboardingOrders;
      setStorageElement('onboardingOrders', orders);
      return {
        ...state,
        onboardingOrders: orders,
      };
    }
    case FILL_SIM_ACTIVATION_INFO:
      return {
        ...state,
        simInfo: action.activationInfo,
      };
    case SELECT_ORDER:
      setStorageElement('selectedOrder', action.order);
      return {
        ...state,
        selectedOrder: action.order,
      };
    case VERIFIED:
      return {
        ...state,
        verified: action.verified,
      };
    case SET_IAM_TOKEN:
      setStorageElement('iamToken', action.iamToken);
      return {
        ...state,
        iamToken: action.iamToken,
      };
    case SET_ACTIVATION_STATUS:
      setStorageElement('activationStatus', action.activationStatus);
      return {
        ...state,
        activationStatus: action.activationStatus,
      };
    case ACTIVATION_FLOW_DONE:
      removeStorageElement('iccid');
      removeStorageElement('iamToken');
      removeStorageElement('userInfo');
      removeStorageElement('posaDetails');
      removeStorageElement('activationStatus');
      removeStorageElement('selectedOrder');
      removeStorageElement('onboardingOrders');
      return {
        ...state,
        activationStatus: action.activationStatus,
      };
    case FILL_ICCID:
      return {
        ...state,
        iccid: action.iccid,
      };
    default:
      return state;
  }
};

const useValue = () => useReducer(ActivationReducer, initialState);

export const {
  Provider: ActivationProvider,
  useTrackedState,
  useUpdate: useActivationDispatch,
} = createContainer(useValue);
