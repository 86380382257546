import { useReducer } from 'react';
import { createContainer } from 'react-tracked';
import { otpConfirmed } from 'services/events.service';
import {
  START_API,
  SUCCESS_API,
  FAILED_API,
  RESET_FORM,
  RESET_COUNTER,
  HIDE_COUNTER,
  INVALID_OTP_CODE,
  RESET_ERROR,
} from 'variables';

export const initState = {
  loading: false,
  error: null,
  succes: false,
  otp: '',
  apiCalled: false,
  showCounter: true,
};

export const otpReducer = (state, action) => {
  switch (action.type) {
    case START_API:
      return {
        ...state,
        loading: true,
        error: null,
        otp: action.otp,
        apiCalled: true,
        showCounter: false,
      };
    case SUCCESS_API:
      // this is added here to call the otp confirmation event only in case the otp is valid
      otpConfirmed();
      return {
        ...state,
        loading: false,
        error: null,
        apiCalled: false,
        otp: '',
      };
    case FAILED_API:
      return {
        ...state,
        loading: false,
        error: action.error,
      };

    case RESET_FORM:
      return {
        ...state,
        apiCalled: false,
      };
    case RESET_ERROR:
      return {
        ...state,
        error: null,
      };
    case INVALID_OTP_CODE:
      return {
        ...state,
        error: action.error,
      };
    case RESET_COUNTER:
      return {
        ...state,
        showCounter: true,
      };
    case HIDE_COUNTER:
      return {
        ...state,
        showCounter: false,
      };

    default:
      return state;
  }
};

const useValue = () => useReducer(otpReducer, initState);

export const {
  Provider: OtpProvider,
  useTrackedState,
  useUpdate: useOtpDispatch,
} = createContainer(useValue);
/// /////////////////////////////////////
