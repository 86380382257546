import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { OnboardingProvider } from 'stores/onboarding';
import { UserContextProvider } from 'stores/user.context';
import { ActivationProvider } from 'stores/activation.context';
import { DashboardProvider } from 'stores/dashboard.context';
import { OtpProvider } from 'components/shared/otp/actions';
import { ErrorProvider } from 'stores/error.context';
import { SideWidgetContextProvider } from 'stores/onboarding-side-widget.context';
import { OwnershipTransferProvider } from './stores/transfer-ownership.context';
// import reportWebVitals from './reportWebVitals';

import App from './App';
import 'i18n';

ReactDOM.render(
  <BrowserRouter>
    <Suspense fallback={null}>
      <ErrorProvider>
        <OwnershipTransferProvider>
          <OnboardingProvider>
            <ActivationProvider>
              <DashboardProvider>
                <SideWidgetContextProvider>
                  <UserContextProvider>
                    <OtpProvider>
                      <App />
                    </OtpProvider>
                  </UserContextProvider>
                </SideWidgetContextProvider>
              </DashboardProvider>
            </ActivationProvider>
          </OnboardingProvider>
        </OwnershipTransferProvider>
      </ErrorProvider>
    </Suspense>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
