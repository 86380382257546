import { TASHEEL_PAYMENT_VENDOR } from 'variables';
import { isAppleSafari, isBrowsingFromMobile, isMacOperatingSystem } from 'utility';
import { getStorageElement, removeStorageElement } from './storage.service';

export const getPaymentVendor = () => getStorageElement('appConfig').payment_vendor;

export const isHyperPayPaymentVendor = () =>
  getStorageElement('appConfig').payment_vendor === 'hyperpay';

export const isTapPaymentVendor = () => getStorageElement('appConfig').payment_vendor === 'tap';

export const clearPaymentDataFromStorage = () => {
  removeStorageElement('orderId');
  removeStorageElement('card_type');
  removeStorageElement('checkoutId');
  removeStorageElement('transactionId');
  removeStorageElement('onboardingOrder');
  removeStorageElement('payment-completed');
  removeStorageElement('logged_out_user_details');
  removeStorageElement('dashboard_transactionId');
  removeStorageElement('rechargeOptionEventsObject');
};

export const showApplePay = () =>
  isAppleSafari() && isHyperPayPaymentVendor()
    ? true
    : (isBrowsingFromMobile() && isMacOperatingSystem() && isHyperPayPaymentVendor()) || false;

export const isTasheelInstallementSelected = () =>
  getStorageElement('installment-vendor', 'cookies') === TASHEEL_PAYMENT_VENDOR;

export const isTasheelTapBaseeta = () => isTasheelInstallementSelected() && isTapPaymentVendor();
