import React from 'react';
import PropTypes from 'prop-types';
import classes from './style.module.scss';

function Spinner({ size, className = '' }) {
  const classesList = [`${classes.ldsDefault} ${className}`];
  if (size === 'small') {
    classesList.push(classes.smallSpinner);
  }
  if (size === 'full-screen') {
    classesList.push(classes.fullScreen);
  }
  return (
    <div className={classesList.join(' ')}>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

Spinner.propTypes = {
  size: PropTypes.string,
  className: PropTypes.string,
};
export default Spinner;
