import PropTypes from 'prop-types';
import { memo } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classes from './style.module.scss';

const Button = memo(
  ({
    action,
    type,
    theme,
    path,
    label,
    disabled,
    submit,
    className,
    icon,
    state,
    qaSelector = '',
    ...newProps
  }) => {
    const { i18n } = useTranslation();
    const btnClasses = [classes[i18n.language === 'en' ? 'LTR' : 'RTL'], classes.btn, qaSelector];
    switch (theme) {
      case 'primary':
        btnClasses.push(classes.btnPrimary);
        break;
      case 'secondary':
        btnClasses.push(classes.btnSecondary);
        break;
      case 'primary-dark':
        btnClasses.push(classes.btnPrimaryDark);
        break;
      default:
        btnClasses.push(classes.btnPrimary);
    }
    if (className) {
      btnClasses.push(className);
    }

    // <input
    //   type={submit ? "submit" : "button"}
    //   className={btnClasses.join(" ")}
    //   disabled={disabled ? "disabled" : null}
    //   onClick={action}
    //   defaultValue={label}
    //   {...newProps}
    // />

    let content = (
      <button
        type={submit ? 'submit' : 'button'}
        className={btnClasses.join(' ')}
        disabled={disabled ? 'disabled' : null}
        onClick={action}
        {...newProps}
      >
        {icon || null} {label}
      </button>
    );
    if (type === 'link') {
      content = (
        <>
          <Link
            to={{
              state,
              pathname: path,
            }}
            className={btnClasses.join(' ')}
          >
            {label}
          </Link>
        </>
      );
    }

    return content;
  }
);

Button.propTypes = {
  action: PropTypes.func,
  type: PropTypes.string,
  theme: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  submit: PropTypes.string,
  icon: PropTypes.string,
  state: PropTypes.string,
  className: PropTypes.string,
  qaSelector: PropTypes.string,
};

export default Button;
