import PropTypes from 'prop-types';
import IconClose from 'components/icons/close';
import blackCloseIcon from 'assets/img/blackCloseIcon.svg';
import classes from './style.module.scss';

const ActionSheet = ({ children, close, width, backgroundColor, textAlignment = 'left' }) => (
  <div className={classes.boxDetails}>
    <div
      className={classes.boxDetailsWrapper}
      style={{
        maxWidth: `${width}px`,
        textAlign: `${textAlignment}`,
      }}
    >
      <div
        className={classes.boxDetailsWrapperInner}
        style={{ backgroundColor: `${backgroundColor}` }}
      >
        {!!close && (
          <div
            className={
              backgroundColor
                ? `${classes.close} closeIcon`
                : `${classes.close} ${classes.closeWithoutBackground} closeIcon`
            }
            role="button"
            aria-hidden="true"
            onClick={() => {
              close(null);
            }}
          >
            {backgroundColor ? (
              <IconClose className={classes.icon} />
            ) : (
              <img src={blackCloseIcon} alt={blackCloseIcon} />
            )}
          </div>
        )}

        {children}
      </div>
    </div>
  </div>
);
ActionSheet.propTypes = {
  close: PropTypes.func,
  width: PropTypes.number,
  textAlignment: PropTypes.string,
  backgroundColor: PropTypes.string,
  children: PropTypes.element.isRequired,
};
export default ActionSheet;
