import md5 from 'md5';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useError } from 'stores/error.context';
import Modal from 'components/ui-elements/modal';
import warningStatus from 'assets/img/warning.svg';
import errorStatus from 'assets/img/errorStatus.svg';
import { getStorageElement } from 'services/storage.service';
import classes from './style.module.scss';

const ErrorPopup = ({
  desc,
  close,
  errorMsg,
  errorCode,
  subTitleStyle,
  hideCloseButton,
  cancelButtonTitle,
  isWarning = false,
  cancelButtonAction,
  hideCloseIcon = false,
}) => {
  let errorCodeDesc;
  const { t } = useTranslation();
  const { error, clearError } = useError();
  const errorObj = errorMsg || error?.error;
  const deviceId = getStorageElement('uuid');
  const subTitle = error?.source === 'semati' ? t(`semati_error_title_${error?.code}`) : errorObj;
  const deviceDesc = deviceId ? `${t('generic_lbl_device_id')}: ${md5(deviceId)}` : '';
  if (errorCode) {
    errorCodeDesc = `${t('generic_lbl_error_code')}: ${errorCode} \n`;
  } else {
    errorCodeDesc = error?.code ? `${t('generic_lbl_error_code')}: ${error?.code} \n` : '';
  }
  const description =
    error?.source === 'semati'
      ? t(`semati_error_desc_${error?.code}`)
      : `${errorCodeDesc} ${deviceDesc}`;
  return (
    <>
      {errorObj && (
        <Modal
          width="500px"
          subTitle={subTitle}
          desc={description || desc}
          cancel={close || clearError}
          hideCloseIcon={hideCloseIcon}
          iconClass={classes.warningIcon}
          cancelButtonAction={cancelButtonAction}
          subTitleStyle={subTitleStyle || classes.errorMsgStyle}
          height={!hideCloseButton ? '400px' : '300px'}
          imgSrc={isWarning ? warningStatus : errorStatus}
          cancelButtonTitle={cancelButtonTitle || (!hideCloseButton && t('generic_btn_try_again'))}
        />
      )}
    </>
  );
};

ErrorPopup.propTypes = {
  close: PropTypes.func,
  desc: PropTypes.string,
  isWarning: PropTypes.bool,
  errorMsg: PropTypes.string,
  errorCode: PropTypes.string,
  hideCloseIcon: PropTypes.bool,
  hideCloseButton: PropTypes.bool,
  subTitleStyle: PropTypes.string,
  cancelButtonAction: PropTypes.func,
  cancelButtonTitle: PropTypes.string,
};

export default ErrorPopup;
