import HTTP from 'services/http.service';

export const getCurrentOrder = function () {
  const config = {
    method: 'get',
    url: `/onboarding/orders/current`,
  };
  return HTTP(config);
};

export const getCurrentOrderData = function (orderId) {
  const config = {
    method: 'get',
    url: `/onboarding/orders/${orderId}/get`,
  };
  return HTTP(config);
};

export const getNationalities = function () {
  const config = {
    method: 'get',
    url: `/lookup/nationalities`,
  };
  return HTTP(config);
};

export const getOperaters = function () {
  const config = {
    method: 'get',
    url: `/lookup/operators`,
  };
  return HTTP(config);
};

export const getSTCCDeliveryTimes = function () {
  const config = {
    method: 'get',
    url: `/lookup/delivery_times`,
  };
  return HTTP(config);
};

export const initalizeOnboardingOrder = function (ICCID) {
  const config = {
    method: 'post',
    url: '/onboarding/orders',
    data: {
      iccid: ICCID,
    },
  };
  return HTTP(config);
};

export const getPlans = function (planType = 'all', params = null) {
  let url = null;
  switch (planType) {
    case 'prepaid':
    case 'Prepaid':
      url = `/plans/prepaid`;
      break;
    case 'postpaid':
    case 'Postpaid':
      url = `/plans/postpaid`;
      break;

    default:
      url = '/plans';
  }
  const config = {
    method: 'get',
    url,
    params,
  };
  return HTTP(config);
};

export const selectOrderPlan = function (orderId, planId, agreed = false, resetPlan) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/plans`,
    data: {
      plan_id: planId,
      require_data_sim: agreed,
      reset_plan: resetPlan,
    },
  };
  return HTTP(config);
};
export const currentByPlan = function (planId) {
  const config = {
    method: 'get',
    url: `/onboarding/orders/current_by_plan/${planId}?require_data_sim=true`,
  };
  return HTTP(config);
};

export const setNumberOrderType = function (orderId, orderType) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/numbers/order_type`,
    data: {
      number_order_type: orderType,
    },
  };
  return HTTP(config);
};

export const getNumbers = function (vanityId, nextPage) {
  const config = {
    method: 'get',
    url: `/numbers${
      vanityId ? `?vanity_id=${vanityId}&next_page=${nextPage}` : `?next_page=${nextPage}`
    }`,
  };
  return HTTP(config);
};

export const getVanities = function () {
  const config = {
    method: 'get',
    url: `/numbers/vanities`,
  };
  return HTTP(config);
};

export const reserveNumber = function (orderId, number) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/numbers/reserve`,
    data: {
      identifier: number,
    },
  };
  return HTTP(config);
};

export const reserveMNP = function (orderId, number, operator) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/mnp`,
    data: {
      mnp_number: number,
      mnp_operator: operator,
    },
  };
  return HTTP(config);
};

export const unreserveNumber = function (orderId) {
  const config = {
    method: 'delete',
    url: `/onboarding/orders/${orderId}/numbers/unreserve`,
  };
  return HTTP(config);
};

export const checkIdEligibility = function (orderId, nationalityId, nationalityIdNumber) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/eligibility/is_eligible`,
    data: {
      nationality_id: nationalityId,
      nationality_id_number: nationalityIdNumber,
    },
  };
  return HTTP(config);
};

export const submitCustomerInfo = function (orderId, name, mobile, email) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/customer_info`,
    data: {
      customer_name: name,
      mobile_number: mobile,
      email,
    },
  };
  return HTTP(config);
};

export const submitCustomerPassword = function (orderId, password) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/profiles/customer_password`,
    data: {
      password,
    },
  };
  return HTTP(config);
};

export const verifyOnboardingOtp = function (data) {
  const config = {
    method: 'post',
    url: '/otp/verify',
    data,
  };
  return HTTP(config);
};

export const getAreas = function () {
  const config = {
    method: 'get',
    url: `/lookup/home_delivery_areas`,
  };
  return HTTP(config);
};

export const getCities = function () {
  const config = {
    method: 'get',
    url: `/lookup/home_delivery_cities`,
  };
  return HTTP(config);
};

export const submitDeliveryInfo = function (orderId, data) {
  const config = {
    method: 'post',
    url: `onboarding/orders/${orderId}/delivery_info`,
    data,
  };
  return HTTP(config);
};

export const enhancedNumberBooking = function (pattern, nextPage) {
  const config = {
    method: 'get',
    url: `numbers/enhanced_numbers?pattern=${pattern}&next_page=${nextPage}`,
  };
  return HTTP(config);
};

export const initializeStores = function () {
  const config = {
    method: 'get',
    url: `/stores/get_stores`,
  };
  return HTTP(config);
};

export const getDeliveryTimeNeeded = function (location, orderId, checkoutType) {
  let url = '';
  if (checkoutType === 4) {
    url = `geofences/delivery_hours?latitude=${location.lat}&longitude=${location.lng}&checkout_id=${orderId}`;
  } else {
    url = `geofences/delivery_hours?latitude=${location.lat}&longitude=${location.lng}`;
  }
  const config = {
    method: 'get',
    url,
  };
  return HTTP(config);
};

export const setNumberSimType = function (simType, orderId) {
  const config = {
    method: 'post',
    url: `/onboarding/orders/${orderId}/sim_type`,
    data: {
      sim_type: simType,
    },
  };
  return HTTP(config);
};

export const getCompatibleDevicesList = function () {
  const config = {
    method: 'get',
    url: `/lookup/esim_devices`,
  };
  return HTTP(config);
};
