import { createContext, useReducer, useCallback } from 'react';
// import { getSession } from 'services/http.service';
import { SET_USER_INFO } from 'variables';
import PropTypes from 'prop-types';

const UserContext = createContext({
  user: null,
  mobileNumber: null,
  /* 
      listing functions her not nececary but 
      it will help us for editor autocomplete
  */
  setUserInfo: () => {},
});

const userReducer = (state, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      if (!state.user) {
        const user = { ...action.info };
        return {
          ...state,
          user,
        };
      }
      return {
        ...state,
        user: { ...action.info },
      };
    default:
      return state;
  }
};

const initState = {
  user: null,
};

export const UserContextProvider = ({ children }) => {
  const [userState, dispatch] = useReducer(userReducer, initState);

  const setUserInfo = useCallback((data) => {
    dispatch({ type: SET_USER_INFO, info: data });
  }, []);

  const context = {
    user: userState.user,
    setUserInfo,
  };

  return <UserContext.Provider value={context}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default UserContext;
