import { v4 as uuidv4 } from 'uuid';
import {
  getAvatars,
  getUserAuth,
  getProfileData,
  getCurrentBill,
  getAppConfig,
} from 'api/user.api';
import { getCurrentOrder } from 'api/onboarding.js';
import { promiseHandler } from 'utility';
import { userLoggedOutSuccess } from 'services/events.service';
import { setSession, setDeviceId } from './http.service';
import { getStorageElement, setStorageElement, removeStorageElement } from './storage.service';

const generateUUID = () => {
  const uuidCode = uuidv4();
  return uuidCode;
};
const updateSession = (uuid, authToken) => {
  setSession(authToken);
  setStorageElement('authToken', authToken);
  if (uuid) {
    setDeviceId(uuid);
    setStorageElement('uuid', uuid);
  }
  // 4- update session
  // if (!HTTP.getSession()) {
  //     HTTP.setSession(authToken);
  //     HTTP.setDeviceId(uuid)
  // }
};

const updateAuthToken = (authToken) => {
  setStorageElement('authToken', authToken);
  setSession(authToken);
};

export const clearEcommerceSDKData = () => {
  removeStorageElement('card_type');
  removeStorageElement('eCommerceObj');
  removeStorageElement('transactionId');
  removeStorageElement('token', 'cookies');
  removeStorageElement('msisdn', 'cookies');
  removeStorageElement('eCommerceEventsObj');
  removeStorageElement('eCommerceCheckoutObj');
  removeStorageElement('ec_user', 'cookies');
  removeStorageElement('variantId', 'cookies');
  removeStorageElement('productId', 'cookies');
  removeStorageElement('checkout-id', 'cookies');
  removeStorageElement('ec_contacts', 'cookies');
  removeStorageElement('ec_location', 'cookies');
  removeStorageElement('payment_type', 'cookies');
  removeStorageElement('refreshToken', 'cookies');
  removeStorageElement('ec_selected_tab', 'cookies');
  removeStorageElement('checkout-token', 'cookies');
  removeStorageElement('checkout-address', 'cookies');
  removeStorageElement('ecommerce-payment-completed');
  removeStorageElement('installment-vendor', 'cookies');
  removeStorageElement('editCheckoutLineId', 'cookies');
  removeStorageElement('Authorization-selfcare', 'cookies');
};

export const clearLoggedInUserData = () => {
  removeStorageElement('profileId');
  removeStorageElement('authToken');
  removeStorageElement('planDetails');
  removeStorageElement('user_mobile');
  removeStorageElement('userDetails');
  removeStorageElement('isUserRegistered');
  removeStorageElement('guestUserLoggedIn');
  removeStorageElement('esim_profile_link');
  removeStorageElement('dataSimPickupOrderId');
  removeStorageElement('userLoggedIn', 'cookies');
  removeStorageElement('loggedInAuthToken', 'cookies');
  removeStorageElement('enforce_user_registration_modal_shown');
};

export const clearOnboardingData = () => {
  removeStorageElement('orderId');
  removeStorageElement('numbers');
  removeStorageElement('nextPage');
  removeStorageElement('bundleType');
  removeStorageElement('posaDetails');
  removeStorageElement('onboardingOrder');
  removeStorageElement('notEligibleTimes');
  removeStorageElement('searchFilteration');
  removeStorageElement('onboardingEventsObj');
};
export const initiateUser = async (isComingFromUnauthorizedError) => {
  // 1- get uuid and authToken from sessionStorage
  let uuid = getStorageElement('uuid');
  let authToken = getStorageElement('authToken');
  // 2- check if uuid  exist
  if (!uuid) {
    uuid = generateUUID();
    const userAuthReponse = await getUserAuth({ device_id: uuid });
    authToken = userAuthReponse.data.auth_token;
    updateSession(uuid, authToken);
    return userAuthReponse;
  }

  // 3- check if session exist
  if (!authToken) {
    const userAuthReponse = await getUserAuth({ device_id: uuid });
    authToken = userAuthReponse.data.auth_token;
    updateAuthToken(authToken);
    return authToken;
  }
  if (isComingFromUnauthorizedError) {
    const userAuthReponse = await getUserAuth({ device_id: uuid });
    authToken = userAuthReponse.data.auth_token;
    updateAuthToken(authToken);
    window.location = `${window.location.origin}`;
    clearOnboardingData();
    clearEcommerceSDKData();
    clearLoggedInUserData();
    return userAuthReponse;
  }
  updateSession(uuid, authToken);
};

export const checkCurrentOrder = async (orderId) => getCurrentOrder(orderId);

export const checkAppConfig = async () => getAppConfig();

export const logout = async (path) => {
  userLoggedOutSuccess();
  clearEcommerceSDKData();
  clearLoggedInUserData();
  window.location = `${window.location.origin}/${path}`;
};

export const initializeBillInformation = async () => {
  const [bill, error] = await promiseHandler(() => getCurrentBill());
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return bill;
};

export const intalizeProfileData = async () => {
  const [bill, error] = await promiseHandler(() => getProfileData());
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return bill;
};

export const intalizeAvatarslist = async () => {
  const [avatars, error] = await promiseHandler(() => getAvatars());
  if (error) {
    // TODO general error component
    // TODO create general error context
    return;
  }
  return avatars;
};
