import PropTypes from 'prop-types';
import { createContext, useReducer, useCallback } from 'react';
import { SET_WIDGET } from 'variables';

const SideWidgetContext = createContext({
  sideWidget: '',
  /* 
      listing functions her not nececary but 
      it will help us for editor autocomplete
  */
  setSideWidget: () => {},
});

const SideWidgetReducer = (state, action) => {
  switch (action.type) {
    case SET_WIDGET:
      return {
        ...state,
        sideWidget: action.sideWidget,
      };
    default:
      return state;
  }
};

const initState = {
  sideWidget: '',
};

export const SideWidgetContextProvider = ({ children }) => {
  const [sideWidgetState, dispatch] = useReducer(SideWidgetReducer, initState);

  const setSideWidget = useCallback((sideWidget) => {
    dispatch({ type: SET_WIDGET, sideWidget });
  }, []);

  const context = {
    sideWidget: sideWidgetState.sideWidget,
    setSideWidget,
  };

  return <SideWidgetContext.Provider value={context}>{children}</SideWidgetContext.Provider>;
};

SideWidgetContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export default SideWidgetContext;
