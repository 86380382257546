/* App settings */

const Settings = {
  ...process.env,
};

Settings.format = {
  email: /^[a-z0-9]+([-._][a-z0-9]+)*@([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,4}$/,
  phonenumber: /(^5\d{8}$)|(^05\d{8}$)|(^9665\d{8}$)|(^009665\d{8}$)|(^[+]9665\d{8}$)/,
  loginPhoneNumber:
    /(^5\d{8}$)|(^05\d{8}$)|(^9665\d{8}$)|(^009665\d{8}$)|(^[+]9665\d{8}$)|(^966831\d{9}$)|(^831\d{9}$)/,
  idNumber: /^[a-zA-Z0-9]{7,14}$/,
  hasOnlyDigits: /^([a-zA-Z]|[+])?\d+$/,
  accountID: /^(?!\s*$)/,
  dynamicRechargeAmount: /^\d{1,5}$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!$%@#£€*?&_=+-]{6,}$/,
  notEmptyString: /^(?!\s*$)/,
  passwordMinLength: /^[a-zA-Z\d!$%@#£€*?&_=+-]{8,}$/,
  passwordOneUpper: /(?=.*[A-Z])/,
  passwordOneLower: /(?=.*[a-z])/,
  passwordOneDigit: /(?=.*\d)/,
  passwordSpecialCharacters: /(?=.*[!$%@#£€*?&_=+-])/,
  saudi_id: /^1[0-9]{9}$/,
  iqama_id: /^2[0-9]{9}$/,
  temporary_iqama: /^[3-6][0-9]{9}$/,
  visitor_identity: /^[3-6][0-9]{9}$/,
  ICCID: /^[0-9]{18,19}$/,
  voucher_code: /^[0-9]{14}$/,
  puk: /^[0-9]{8}$/,
  currentStateStack: [
    'plan_selection',
    'sim_type_selection',
    'number_order_type_selection',
    'number_selection',
    'eligibility_check',
    'account_details',
    'account_verification',
    'account_password',
    'delivery_details',
    'payment',
    'payment-form',
    'payment-verification',
  ],
  dataSimCurrentStateStack: [
    'plan_selection',
    'eligibility_check',
    'account_details',
    'account_verification',
    'account_password',
    'delivery_details',
    'payment',
    'payment-form',
    'payment-verification',
  ],
  ownerShipTransferStack: [
    'sim_type_selection',
    'eligibility_check',
    'account_details',
    'account_password',
    'delivery_details',
    'payment',
    'payment-form',
    'payment-verification',
  ],
};

Settings.get = function (setting) {
  return Settings[setting];
};

export default Settings;
