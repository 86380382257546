export const GET_URL_PATH_FIRST_PART = 'GET_URL_PATH_FIRST_PART';
export const GET_URL_PATH_LAST_PART = 'GET_URL_PATH_LAST_PART';
export const GET_FULL_PATH_WITHOUT_LANG = 'GET_FULL_PATH_WITHOUT_LANG';

export const SET_USER_INFO = 'SET_USER_INFO';
export const SET_ONBOARDING_FLOW = 'SET_ONBOARDING_FLOW';

export const LOGGED_OUT_USER = 'LOGGED_OUT_USER';

export const START_API = 'START_API';
export const SUCCESS_API = 'SUCCESS_API';
export const FAILED_API = 'FAILED_API';

export const SIGNIN_FORM = 'signin';
export const SIGNUP_FORM = 'signup';

export const PREPAID = 'prepaid';
export const POSTPAID = 'postpaid';
export const RESET_ERROR = 'RESET_ERROR';
export const RESET_FORM = 'RESET_FORM';
export const RESET_COUNTER = 'RESET_COUNTER';
export const HIDE_COUNTER = 'HIDE_COUNTER';
export const INVALID_OTP_CODE = 'INVALID_OTP_CODE';

export const CONDITIONS_MATCH_ALL = 'CONDITIONS_MATCH_ALL';
export const CONDITIONS_MATCH_ANY = 'CONDITIONS_MATCH_ANY';

export const UPDATE_STEP = 'UPDATE_STEP';

export const DESTINATION_TYPE_EMAIL = 'DESTINATION_TYPE_EMAIL';
export const DESTINATION_TYPE_MOBILE = 'DESTINATION_TYPE_MOBILE';

export const SAUDI_ID = 'saudi_id';
export const IQAMA_ID = 'iqama_id';
export const TEMPORARY_IQAMA = 'temporary_iqama';
export const VISITOR_IDENTITY = 'visitor_identity';

export const ACCESS_GLOBAL = 'ACCESS_GLOBAL';
export const ACCESS_LOGGED_OUT_USER = 'ACCESS_LOGGED_OUT_USER';
export const ACCESS_LOGGED_IN_USER = 'ACCESS_LOGGED_IN_USER';

export const UPDATE_FLOW = 'UPDATE_FLOW';
export const SET_REFERANCE_VALUE = 'SET_REFERANCE_VALUE';
export const SET_REFERANCE_ID = 'SET_REFERANCE_ID';
export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';
export const SET_WIDGET = 'SET_WIDGET';
export const SET_CURRENT_STATE = 'SET_CURRENT_STATE';
export const INIT_ORDER_ID = 'INIT_ORDER_ID';
export const SELECT_PLAN_TYPE = 'SELECT_PLAN_TYPE';
export const SELECT_PLAN = 'SELECT_PLAN';
export const SELECT_ORDER_TYPE = 'SELECT_ORDER_TYPE';
export const ORDER_TYPE_MNP = 'mnp';
export const ORDER_TYPE_NEW_LINE = 'newLine';
export const ESIM = 'eSim';
export const SIM = 'sim';
export const SELECT_SIM_TYPE = 'SELECT_SIM_TYPE';
export const SELECT_FLOW_TYPE = 'SELECT_FLOW_TYPE';
export const SELECT_NUMBER = 'SELECT_NUMBER';
export const UNSELECT_NUMBER = 'UNSELECT_NUMBER';
export const FILL_ID_INFO = 'FILL_ID_INFO';
export const FILL_CONTACT_INFO = 'FILL_CONTACT_INFO';
export const VERIFIED = 'VERIFIED';
export const DELIVERY_ADDRESS_INFO = 'DELIVERY_ADDRESS_INFO';
export const SUMMARY_CHECKED = 'SUMMARY_CHECKED';
export const ALL = 'ALL';
export const SET_ACTIVE_VANITY = 'SET_ACTIVE_VANITY';
export const SET_NUMBERS_BY_VANITY = 'SET_NUMBERS_BY_VANITY';
export const SELECT_ONBOARDING_FLOW_TYPE = 'SELECT_ONBOARDING_FLOW_TYPE';
export const FILL_USER_INFO = 'FILL_USER_INFO';
export const SET_ORDERS_LIST = 'SET_ORDERS_LIST';
export const SELECT_ORDER = 'SELECT_ORDER';
export const FILL_SIM_ACTIVATION_INFO = 'FILL_SIM_ACTIVATION_INFO';
export const SET_ACTIVATION_STATUS = 'SET_ACTIVATION_STATUS';
export const ACTIVATION_FLOW_DONE = 'ACTIVATION_FLOW_DONE';
export const SET_IAM_TOKEN = 'SET_IAM_TOKEN';
export const INIT_CHECKOUT = 'INIT_CHECKOUT';
export const PAYMENT_COMPLETED = 'PAYMENT_COMPLETED';

export const SET_RECHARGES = 'SET_RECHARGES';
export const SELECT_RECHARGE = 'SELECT_RECHARGE';
export const VALID_VOUCHER = 'VALID_VOUCHER';
export const SET_VOUCHER_CODE_VALUE = 'SET_VOUCHER_CODE_VALUE';
export const SELECT_RECHARGE_OPTION = 'SELECT_RECHARGE_OPTION';
export const RESET_ORDER = 'RESET_ORDER';
export const CONFIRMATION = 'CONFIRMATION';
export const SHOW_MODAL = 'SHOW_MODAL';
export const HIDE_MODAL = 'HIDE_MODAL';
export const RESET_ORDER_COUNTER = 'RESET_ORDER_COUNTER';
export const SHOW_ORDER_COUNTER = 'SHOW_ORDER_COUNTER';
export const HIDE_ORDER_COUNTER = 'HIDE_ORDER_COUNTER';
export const CLEAR_CITIES = 'CLEAR_CITIES';
export const SELECTED_DELIVERY_AREA = 'SELECTED_DELIVERY_AREA';
export const SCHEDULE_COURIER_MODULE_OPENED = 'SCHEDULE_COURIER_MODULE_OPENED';
export const START_PAGINATION_API = 'START_PAGINATION_API';
export const STOP_PAGINATION_API = 'STOP_PAGINATION_API';
export const SET_IS_REGISTERED = 'SET_IS_REGISTERED';
export const SET_DASHBOARD_ACTIVE_TAB = 'SET_DASHBOARD_ACTIVE_TAB';
export const RESET_PLAN_TYPE = 'RESET_PLAN_TYPE';
export const FILL_ICCID = 'FILL_ICCID';
export const SELF_ACTIVATION_TYPE = 'posa';
export const SELF_ACTIVATION_TYPE_2 = 'self_activation';
export const ADDITIONAL_DATA_SIM_ENUM = '1';
export const CHANGE_PLAN_ENUM = '2';
export const ECOMMERCE_ENUM = '4';
export const FILL_TRANSFER_OWNERSHIP_DATA = 'FILL_TRANSFER_OWNERSHIP_DATA';
export const REMOVE_TRANSFER_OWNERSHIP_DATA = 'REMOVE_TRANSFER_OWNERSHIP_DATA';
export const FILL_TRANSFER_OWNERSHIP_OTP_DATA = 'FILL_TRANSFER_OWNERSHIP_OTP_DATA';
export const REMOVE_TRANSFER_OWNERSHIP_OTP_DATA = 'REMOVE_TRANSFER_OWNERSHIP_OTP_DATA';
export const FILL_TRANSFER_OWNERSHIP_CONFIRMATION_SCREEN_DATA =
  'FILL_TRANSFER_OWNERSHIP_CONFIRMATION_SCREEN_DATA';
export const SIM_TYPES = {
  sim: 0,
  esim: 1,
  both: 2,
};

export const NAFATH_SERVICES = {
  NEW_MOBILE: 'new_mobile', // activation
  NEW_SIM: 'new_sim', // requesting
  CHANGE_PLAN: 'change_plan', // change plan
  TRANSFER_OWNERSHIP_LOCAL: 'transfer_ownership_local',
  TRANSFER_OWNERSHIP_GLOBAL: 'transfer_ownership_global',
};

export const ERROR_CODES = {
  PENDING_NAFATH_TOKEN: -700,
  REJECTED_NAFATH_TOKEN: -701,
  NEW_PENDING_NAFATH_TOKEN: -615,
  NEW_REJECTED_NAFATH_TOKEN: -616,
};
export const INSTALLMENT_VENDOR = 'INSTALLMENT';
export const TAMARA_PAYMENT_VENDOR = 'tamara';
export const TASHEEL_PAYMENT_VENDOR = 'tasheel';
export const REGULAR_VANITY_ID = 3;
export const PREPAID_ID = 1;
export const POSTPAID_ID = 2;
