import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import IconClose from 'assets/img/close-dark.svg';
import SalamAppLogo from 'assets/img/salam-app-logo.svg';
import classes from './style.module.scss';

const DownloadAppBanner = () => {
  const [isEnabled, setIsEnabled] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    const isHideBanner = sessionStorage.getItem('HIDE_BANNER');
    setIsEnabled(isHideBanner !== 'true');
  }, []);

  const handleOnHideBanner = () => {
    sessionStorage.setItem('HIDE_BANNER', 'true');
    setIsEnabled(false);
  };

  const handleOnOpenStore = () => {
    window.open(process.env.REACT_APP_DOWNLOAD_APPS_LINK, '_blank');
    setIsEnabled(false);
  };

  return (
    <>
      {!!isEnabled && (
        <div className={classes.bannerContainer}>
          <div className={classes.bannerBody}>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div className={classes.hideBanner} onClick={handleOnHideBanner}>
              <LazyLoadImage src={IconClose} alt="close" width="16px" height="16px" />
            </div>
            {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
            <div onClick={handleOnOpenStore}>
              <div className={classes.bannerDetails}>
                <div>
                  <LazyLoadImage src={SalamAppLogo} alt="app logo" width="60px" height="60px" />
                </div>
                <div className={classes.bannerDetailsContent}>
                  <div className={classes.titleBanner}>
                    {t('scr_28.0_download_banner_lbl_line01')}
                  </div>
                  <div className={classes.nameBanner}>MySalam</div>
                  <div className={classes.linkBanner}>
                    <span>{t('scr_28.0_download_banner_lbl_line03')}</span>
                    <span className={classes.decoratedText}>
                      {t('scr_28.0_download_banner_lbl_line04')}
                    </span>
                  </div>
                </div>
              </div>
              <div className={classes.bannerFooter}>
                <div className="banner-footer">{t('scr_28.0_download_banner_lbl_line05')}</div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default DownloadAppBanner;
